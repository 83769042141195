/**
 * Generated by @openapi-codegen
 *
 * @version v1
 */
import * as reactQuery from '@tanstack/react-query';
import { usePricerApiContext, PricerApiContext } from './pricerApiContext';
import type * as Fetcher from './pricerApiFetcher';
import { pricerApiFetch } from './pricerApiFetcher';
import type * as Schemas from './pricerApiSchemas';

export type GetTicketAttributesError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.ProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.ProblemDetails;
    }
>;

export type GetTicketAttributesResponse = Schemas.EnumDefinitionDto[];

export type GetTicketAttributesVariables = PricerApiContext['fetcherOptions'];

export const fetchGetTicketAttributes = (variables: GetTicketAttributesVariables, signal?: AbortSignal) =>
  pricerApiFetch<GetTicketAttributesResponse, GetTicketAttributesError, undefined, {}, {}, {}>({
    url: '/v1/Client/ticket-attributes',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetTicketAttributes = <TData = GetTicketAttributesResponse>(
  variables: GetTicketAttributesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetTicketAttributesResponse, GetTicketAttributesError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = usePricerApiContext(options);
  return reactQuery.useQuery<GetTicketAttributesResponse, GetTicketAttributesError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/Client/ticket-attributes',
      operationId: 'getTicketAttributes',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetTicketAttributes({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetOrganizationPricingSettingError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type GetOrganizationPricingSettingVariables = PricerApiContext['fetcherOptions'];

export const fetchGetOrganizationPricingSetting = (
  variables: GetOrganizationPricingSettingVariables,
  signal?: AbortSignal,
) =>
  pricerApiFetch<Schemas.OrganizationPricingSettingDto, GetOrganizationPricingSettingError, undefined, {}, {}, {}>({
    url: '/v1/Client/organization-pricing-setting',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetOrganizationPricingSetting = <TData = Schemas.OrganizationPricingSettingDto>(
  variables: GetOrganizationPricingSettingVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.OrganizationPricingSettingDto, GetOrganizationPricingSettingError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = usePricerApiContext(options);
  return reactQuery.useQuery<Schemas.OrganizationPricingSettingDto, GetOrganizationPricingSettingError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/Client/organization-pricing-setting',
      operationId: 'getOrganizationPricingSetting',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetOrganizationPricingSetting({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetPendingChangesStatusQueryParams = {
  ticketGroupId?: number[];
  /**
   * @format int64
   */
  productionId?: number;
};

export type GetPendingChangesStatusError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type GetPendingChangesStatusVariables = {
  queryParams?: GetPendingChangesStatusQueryParams;
} & PricerApiContext['fetcherOptions'];

export const fetchGetPendingChangesStatus = (variables: GetPendingChangesStatusVariables, signal?: AbortSignal) =>
  pricerApiFetch<undefined, GetPendingChangesStatusError, undefined, {}, GetPendingChangesStatusQueryParams, {}>({
    url: '/v1/client/inventory/get-pending',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPendingChangesStatus = <TData = undefined>(
  variables: GetPendingChangesStatusVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<undefined, GetPendingChangesStatusError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = usePricerApiContext(options);
  return reactQuery.useQuery<undefined, GetPendingChangesStatusError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/client/inventory/get-pending',
      operationId: 'getPendingChangesStatus',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetPendingChangesStatus({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateTicketGroupPathParams = {
  /**
   * @format int64
   */
  ticketGroupId: number;
};

export type UpdateTicketGroupError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type UpdateTicketGroupVariables = {
  body?: Schemas.UpdateTicketGroupRequestDto;
  pathParams: UpdateTicketGroupPathParams;
} & PricerApiContext['fetcherOptions'];

export const fetchUpdateTicketGroup = (variables: UpdateTicketGroupVariables, signal?: AbortSignal) =>
  pricerApiFetch<
    Schemas.UpdateTicketGroupResponseDto,
    UpdateTicketGroupError,
    Schemas.UpdateTicketGroupRequestDto,
    {},
    {},
    UpdateTicketGroupPathParams
  >({
    url: '/v1/client/inventory/{ticketGroupId}',
    method: 'patch',
    ...variables,
    signal,
  });

export const useUpdateTicketGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.UpdateTicketGroupResponseDto,
      UpdateTicketGroupError,
      UpdateTicketGroupVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = usePricerApiContext();
  return reactQuery.useMutation<
    Schemas.UpdateTicketGroupResponseDto,
    UpdateTicketGroupError,
    UpdateTicketGroupVariables
  >({
    mutationFn: (variables: UpdateTicketGroupVariables) => fetchUpdateTicketGroup({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetTicketGroupsByIdsQueryParams = {
  ticketGroupIds?: number[];
};

export type GetTicketGroupsByIdsError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.ProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.ProblemDetails;
    }
>;

export type GetTicketGroupsByIdsResponse = Schemas.InventoryResponseDto[];

export type GetTicketGroupsByIdsVariables = {
  queryParams?: GetTicketGroupsByIdsQueryParams;
} & PricerApiContext['fetcherOptions'];

export const fetchGetTicketGroupsByIds = (variables: GetTicketGroupsByIdsVariables, signal?: AbortSignal) =>
  pricerApiFetch<
    GetTicketGroupsByIdsResponse,
    GetTicketGroupsByIdsError,
    undefined,
    {},
    GetTicketGroupsByIdsQueryParams,
    {}
  >({
    url: '/v1/client/inventory/get-multiple',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetTicketGroupsByIds = <TData = GetTicketGroupsByIdsResponse>(
  variables: GetTicketGroupsByIdsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetTicketGroupsByIdsResponse, GetTicketGroupsByIdsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = usePricerApiContext(options);
  return reactQuery.useQuery<GetTicketGroupsByIdsResponse, GetTicketGroupsByIdsError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/client/inventory/get-multiple',
      operationId: 'getTicketGroupsByIds',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetTicketGroupsByIds({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type BulkShareToggleError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type BulkShareToggleVariables = {
  body?: Schemas.TicketGroupShareToggleBulkRequest;
} & PricerApiContext['fetcherOptions'];

export const fetchBulkShareToggle = (variables: BulkShareToggleVariables, signal?: AbortSignal) =>
  pricerApiFetch<
    Schemas.TicketGroupShareToggleBulkResponse,
    BulkShareToggleError,
    Schemas.TicketGroupShareToggleBulkRequest,
    {},
    {},
    {}
  >({
    url: '/v1/client/inventory/share-toggle/bulk',
    method: 'patch',
    ...variables,
    signal,
  });

export const useBulkShareToggle = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.TicketGroupShareToggleBulkResponse,
      BulkShareToggleError,
      BulkShareToggleVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = usePricerApiContext();
  return reactQuery.useMutation<
    Schemas.TicketGroupShareToggleBulkResponse,
    BulkShareToggleError,
    BulkShareToggleVariables
  >({
    mutationFn: (variables: BulkShareToggleVariables) => fetchBulkShareToggle({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetTicketGroupsByIdsV2QueryParams = {
  ticketGroupIds?: number[];
};

export type GetTicketGroupsByIdsV2Error = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.ProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.ProblemDetails;
    }
>;

export type GetTicketGroupsByIdsV2Response = Schemas.InventoryResponseDtoV2[];

export type GetTicketGroupsByIdsV2Variables = {
  queryParams?: GetTicketGroupsByIdsV2QueryParams;
} & PricerApiContext['fetcherOptions'];

export const fetchGetTicketGroupsByIdsV2 = (variables: GetTicketGroupsByIdsV2Variables, signal?: AbortSignal) =>
  pricerApiFetch<
    GetTicketGroupsByIdsV2Response,
    GetTicketGroupsByIdsV2Error,
    undefined,
    {},
    GetTicketGroupsByIdsV2QueryParams,
    {}
  >({
    url: '/v2/client/inventory/get-multiple',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetTicketGroupsByIdsV2 = <TData = GetTicketGroupsByIdsV2Response>(
  variables: GetTicketGroupsByIdsV2Variables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetTicketGroupsByIdsV2Response, GetTicketGroupsByIdsV2Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = usePricerApiContext(options);
  return reactQuery.useQuery<GetTicketGroupsByIdsV2Response, GetTicketGroupsByIdsV2Error, TData>({
    queryKey: queryKeyFn({
      path: '/v2/client/inventory/get-multiple',
      operationId: 'getTicketGroupsByIdsV2',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetTicketGroupsByIdsV2({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetComparablesV2Error = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type GetComparablesV2Variables = {
  body?: Schemas.ComparablesQueryDto;
} & PricerApiContext['fetcherOptions'];

export const fetchGetComparablesV2 = (variables: GetComparablesV2Variables, signal?: AbortSignal) =>
  pricerApiFetch<Schemas.PagedComparableResponseDto, GetComparablesV2Error, Schemas.ComparablesQueryDto, {}, {}, {}>({
    url: '/v2/client/Marketplace/comparables',
    method: 'post',
    ...variables,
    signal,
  });

export const useGetComparablesV2 = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.PagedComparableResponseDto, GetComparablesV2Error, GetComparablesV2Variables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = usePricerApiContext();
  return reactQuery.useMutation<Schemas.PagedComparableResponseDto, GetComparablesV2Error, GetComparablesV2Variables>({
    mutationFn: (variables: GetComparablesV2Variables) => fetchGetComparablesV2({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PostComparablesValidationError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type PostComparablesValidationVariables = {
  body?: Schemas.ComparablesQueryDto;
} & PricerApiContext['fetcherOptions'];

export const fetchPostComparablesValidation = (variables: PostComparablesValidationVariables, signal?: AbortSignal) =>
  pricerApiFetch<
    Schemas.ValidateComparablesResponse,
    PostComparablesValidationError,
    Schemas.ComparablesQueryDto,
    {},
    {},
    {}
  >({
    url: '/v1/client/Marketplace/comparables/validation',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostComparablesValidation = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ValidateComparablesResponse,
      PostComparablesValidationError,
      PostComparablesValidationVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = usePricerApiContext();
  return reactQuery.useMutation<
    Schemas.ValidateComparablesResponse,
    PostComparablesValidationError,
    PostComparablesValidationVariables
  >({
    mutationFn: (variables: PostComparablesValidationVariables) =>
      fetchPostComparablesValidation({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetMarketplacePropertiesByProductionIdPathParams = {
  /**
   * @format int64
   */
  productionId: number;
};

export type GetMarketplacePropertiesByProductionIdError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type GetMarketplacePropertiesByProductionIdVariables = {
  pathParams: GetMarketplacePropertiesByProductionIdPathParams;
} & PricerApiContext['fetcherOptions'];

export const fetchGetMarketplacePropertiesByProductionId = (
  variables: GetMarketplacePropertiesByProductionIdVariables,
  signal?: AbortSignal,
) =>
  pricerApiFetch<
    Schemas.MarketplaceProperties,
    GetMarketplacePropertiesByProductionIdError,
    undefined,
    {},
    {},
    GetMarketplacePropertiesByProductionIdPathParams
  >({
    url: '/v1/client/Marketplace/comparables/{productionId}/properties',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetMarketplacePropertiesByProductionId = <TData = Schemas.MarketplaceProperties>(
  variables: GetMarketplacePropertiesByProductionIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.MarketplaceProperties, GetMarketplacePropertiesByProductionIdError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = usePricerApiContext(options);
  return reactQuery.useQuery<Schemas.MarketplaceProperties, GetMarketplacePropertiesByProductionIdError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/client/Marketplace/comparables/{productionId}/properties',
      operationId: 'getMarketplacePropertiesByProductionId',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetMarketplacePropertiesByProductionId({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetAllEventSummariesError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type GetAllEventSummariesVariables = PricerApiContext['fetcherOptions'];

export const fetchGetAllEventSummaries = (variables: GetAllEventSummariesVariables, signal?: AbortSignal) =>
  pricerApiFetch<Schemas.ProductionsSummaryDto, GetAllEventSummariesError, undefined, {}, {}, {}>({
    url: '/v1/client/Metrics/event-summary',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetAllEventSummaries = <TData = Schemas.ProductionsSummaryDto>(
  variables: GetAllEventSummariesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ProductionsSummaryDto, GetAllEventSummariesError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = usePricerApiContext(options);
  return reactQuery.useQuery<Schemas.ProductionsSummaryDto, GetAllEventSummariesError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/client/Metrics/event-summary',
      operationId: 'getAllEventSummaries',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetAllEventSummaries({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetEventSummaryPathParams = {
  /**
   * @format int64
   */
  productionId: number;
};

export type GetEventSummaryError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type GetEventSummaryVariables = {
  pathParams: GetEventSummaryPathParams;
} & PricerApiContext['fetcherOptions'];

export const fetchGetEventSummary = (variables: GetEventSummaryVariables, signal?: AbortSignal) =>
  pricerApiFetch<Schemas.ProductionMetricsDto, GetEventSummaryError, undefined, {}, {}, GetEventSummaryPathParams>({
    url: '/v1/client/Metrics/event/{productionId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetEventSummary = <TData = Schemas.ProductionMetricsDto>(
  variables: GetEventSummaryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ProductionMetricsDto, GetEventSummaryError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = usePricerApiContext(options);
  return reactQuery.useQuery<Schemas.ProductionMetricsDto, GetEventSummaryError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/client/Metrics/event/{productionId}',
      operationId: 'getEventSummary',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetEventSummary({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetOrganizationDetailsError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type GetOrganizationDetailsVariables = PricerApiContext['fetcherOptions'];

export const fetchGetOrganizationDetails = (variables: GetOrganizationDetailsVariables, signal?: AbortSignal) =>
  pricerApiFetch<Schemas.OrganizationPricingDefaultDto, GetOrganizationDetailsError, undefined, {}, {}, {}>({
    url: '/v1/client/org-defaults',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetOrganizationDetails = <TData = Schemas.OrganizationPricingDefaultDto>(
  variables: GetOrganizationDetailsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.OrganizationPricingDefaultDto, GetOrganizationDetailsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = usePricerApiContext(options);
  return reactQuery.useQuery<Schemas.OrganizationPricingDefaultDto, GetOrganizationDetailsError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/client/org-defaults',
      operationId: 'getOrganizationDetails',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetOrganizationDetails({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateOrganizationDefaultsError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type UpdateOrganizationDefaultsVariables = {
  body?: Schemas.OrganizationPricingDefaultDto;
} & PricerApiContext['fetcherOptions'];

export const fetchUpdateOrganizationDefaults = (variables: UpdateOrganizationDefaultsVariables, signal?: AbortSignal) =>
  pricerApiFetch<
    Schemas.OrganizationPricingDefaultDto,
    UpdateOrganizationDefaultsError,
    Schemas.OrganizationPricingDefaultDto,
    {},
    {},
    {}
  >({ url: '/v1/client/org-defaults', method: 'put', ...variables, signal });

export const useUpdateOrganizationDefaults = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OrganizationPricingDefaultDto,
      UpdateOrganizationDefaultsError,
      UpdateOrganizationDefaultsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = usePricerApiContext();
  return reactQuery.useMutation<
    Schemas.OrganizationPricingDefaultDto,
    UpdateOrganizationDefaultsError,
    UpdateOrganizationDefaultsVariables
  >({
    mutationFn: (variables: UpdateOrganizationDefaultsVariables) =>
      fetchUpdateOrganizationDefaults({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetCalculatedDefaultsPathParams = {
  /**
   * @format int64
   */
  anchorTicketGroupId: number;
};

export type GetCalculatedDefaultsError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type GetCalculatedDefaultsVariables = {
  pathParams: GetCalculatedDefaultsPathParams;
} & PricerApiContext['fetcherOptions'];

export const fetchGetCalculatedDefaults = (variables: GetCalculatedDefaultsVariables, signal?: AbortSignal) =>
  pricerApiFetch<
    Schemas.OrganizationPricingDefaultDto,
    GetCalculatedDefaultsError,
    undefined,
    {},
    {},
    GetCalculatedDefaultsPathParams
  >({
    url: '/v1/client/org-defaults/calculate-defaults/{anchorTicketGroupId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetCalculatedDefaults = <TData = Schemas.OrganizationPricingDefaultDto>(
  variables: GetCalculatedDefaultsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.OrganizationPricingDefaultDto, GetCalculatedDefaultsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = usePricerApiContext(options);
  return reactQuery.useQuery<Schemas.OrganizationPricingDefaultDto, GetCalculatedDefaultsError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/client/org-defaults/calculate-defaults/{anchorTicketGroupId}',
      operationId: 'getCalculatedDefaults',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetCalculatedDefaults({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetOrganizationDetailsLegacyError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type GetOrganizationDetailsLegacyVariables = PricerApiContext['fetcherOptions'];

export const fetchGetOrganizationDetailsLegacy = (
  variables: GetOrganizationDetailsLegacyVariables,
  signal?: AbortSignal,
) =>
  pricerApiFetch<Schemas.OrganizationPricingDefaultDto, GetOrganizationDetailsLegacyError, undefined, {}, {}, {}>({
    url: '/v1/pricer-svc/org-defaults',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetOrganizationDetailsLegacy = <TData = Schemas.OrganizationPricingDefaultDto>(
  variables: GetOrganizationDetailsLegacyVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.OrganizationPricingDefaultDto, GetOrganizationDetailsLegacyError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = usePricerApiContext(options);
  return reactQuery.useQuery<Schemas.OrganizationPricingDefaultDto, GetOrganizationDetailsLegacyError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/pricer-svc/org-defaults',
      operationId: 'getOrganizationDetailsLegacy',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetOrganizationDetailsLegacy({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type UpdateOrganizationDefaultsLegacyError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type UpdateOrganizationDefaultsLegacyVariables = {
  body?: Schemas.OrganizationPricingDefaultDto;
} & PricerApiContext['fetcherOptions'];

export const fetchUpdateOrganizationDefaultsLegacy = (
  variables: UpdateOrganizationDefaultsLegacyVariables,
  signal?: AbortSignal,
) =>
  pricerApiFetch<
    Schemas.OrganizationPricingDefaultDto,
    UpdateOrganizationDefaultsLegacyError,
    Schemas.OrganizationPricingDefaultDto,
    {},
    {},
    {}
  >({
    url: '/v1/pricer-svc/org-defaults',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateOrganizationDefaultsLegacy = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.OrganizationPricingDefaultDto,
      UpdateOrganizationDefaultsLegacyError,
      UpdateOrganizationDefaultsLegacyVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = usePricerApiContext();
  return reactQuery.useMutation<
    Schemas.OrganizationPricingDefaultDto,
    UpdateOrganizationDefaultsLegacyError,
    UpdateOrganizationDefaultsLegacyVariables
  >({
    mutationFn: (variables: UpdateOrganizationDefaultsLegacyVariables) =>
      fetchUpdateOrganizationDefaultsLegacy({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type GetOrganizationPricingSettingAdminQueryParams = {
  /**
   * @default
   */
  filter?: string;
  /**
   * @default
   */
  range?: string;
  /**
   * @default
   */
  sort?: string;
};

export type GetOrganizationPricingSettingAdminError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type GetOrganizationPricingSettingAdminResponse = Schemas.OrganizationPricingSettingDto[];

export type GetOrganizationPricingSettingAdminVariables = {
  queryParams?: GetOrganizationPricingSettingAdminQueryParams;
} & PricerApiContext['fetcherOptions'];

export const fetchGetOrganizationPricingSettingAdmin = (
  variables: GetOrganizationPricingSettingAdminVariables,
  signal?: AbortSignal,
) =>
  pricerApiFetch<
    GetOrganizationPricingSettingAdminResponse,
    GetOrganizationPricingSettingAdminError,
    undefined,
    {},
    GetOrganizationPricingSettingAdminQueryParams,
    {}
  >({
    url: '/v1/admin/organization-pricing-setting',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetOrganizationPricingSettingAdmin = <TData = GetOrganizationPricingSettingAdminResponse>(
  variables: GetOrganizationPricingSettingAdminVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      GetOrganizationPricingSettingAdminResponse,
      GetOrganizationPricingSettingAdminError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = usePricerApiContext(options);
  return reactQuery.useQuery<
    GetOrganizationPricingSettingAdminResponse,
    GetOrganizationPricingSettingAdminError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/admin/organization-pricing-setting',
      operationId: 'getOrganizationPricingSettingAdmin',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetOrganizationPricingSettingAdmin({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PutOrganizationPricingSettingAdminPathParams = {
  organizationKey: string;
};

export type PutOrganizationPricingSettingAdminError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type PutOrganizationPricingSettingAdminVariables = {
  body?: Schemas.PricingModeRequest;
  pathParams: PutOrganizationPricingSettingAdminPathParams;
} & PricerApiContext['fetcherOptions'];

export const fetchPutOrganizationPricingSettingAdmin = (
  variables: PutOrganizationPricingSettingAdminVariables,
  signal?: AbortSignal,
) =>
  pricerApiFetch<
    Schemas.PricingGroupResponse,
    PutOrganizationPricingSettingAdminError,
    Schemas.PricingModeRequest,
    {},
    {},
    PutOrganizationPricingSettingAdminPathParams
  >({
    url: '/v1/admin/organization-pricing-setting/{organizationKey}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutOrganizationPricingSettingAdmin = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PricingGroupResponse,
      PutOrganizationPricingSettingAdminError,
      PutOrganizationPricingSettingAdminVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = usePricerApiContext();
  return reactQuery.useMutation<
    Schemas.PricingGroupResponse,
    PutOrganizationPricingSettingAdminError,
    PutOrganizationPricingSettingAdminVariables
  >({
    mutationFn: (variables: PutOrganizationPricingSettingAdminVariables) =>
      fetchPutOrganizationPricingSettingAdmin({
        ...fetcherOptions,
        ...variables,
      }),
    ...options,
  });
};

export type PostCalculatePriceError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type PostCalculatePriceVariables = {
  body?: Schemas.PriceCalculationRequestDto;
} & PricerApiContext['fetcherOptions'];

export const fetchPostCalculatePrice = (variables: PostCalculatePriceVariables, signal?: AbortSignal) =>
  pricerApiFetch<Schemas.PricingResultDto, PostCalculatePriceError, Schemas.PriceCalculationRequestDto, {}, {}, {}>({
    url: '/v1/client/pricing-engine/calculate-price',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostCalculatePrice = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.PricingResultDto, PostCalculatePriceError, PostCalculatePriceVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = usePricerApiContext();
  return reactQuery.useMutation<Schemas.PricingResultDto, PostCalculatePriceError, PostCalculatePriceVariables>({
    mutationFn: (variables: PostCalculatePriceVariables) =>
      fetchPostCalculatePrice({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PostPricingGroupError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type PostPricingGroupVariables = {
  body?: Schemas.PricingGroupRequest;
} & PricerApiContext['fetcherOptions'];

export const fetchPostPricingGroup = (variables: PostPricingGroupVariables, signal?: AbortSignal) =>
  pricerApiFetch<Schemas.PricingGroupResponse, PostPricingGroupError, Schemas.PricingGroupRequest, {}, {}, {}>({
    url: '/v1/client/pricing-group',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostPricingGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.PricingGroupResponse, PostPricingGroupError, PostPricingGroupVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = usePricerApiContext();
  return reactQuery.useMutation<Schemas.PricingGroupResponse, PostPricingGroupError, PostPricingGroupVariables>({
    mutationFn: (variables: PostPricingGroupVariables) => fetchPostPricingGroup({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PutPricingGroupPathParams = {
  /**
   * @format int64
   */
  pricingGroupId: number;
};

export type PutPricingGroupError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type PutPricingGroupVariables = {
  body?: Schemas.PricingGroupRequest;
  pathParams: PutPricingGroupPathParams;
} & PricerApiContext['fetcherOptions'];

export const fetchPutPricingGroup = (variables: PutPricingGroupVariables, signal?: AbortSignal) =>
  pricerApiFetch<
    Schemas.PricingGroupResponse,
    PutPricingGroupError,
    Schemas.PricingGroupRequest,
    {},
    {},
    PutPricingGroupPathParams
  >({
    url: '/v1/client/pricing-group/{pricingGroupId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutPricingGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<Schemas.PricingGroupResponse, PutPricingGroupError, PutPricingGroupVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = usePricerApiContext();
  return reactQuery.useMutation<Schemas.PricingGroupResponse, PutPricingGroupError, PutPricingGroupVariables>({
    mutationFn: (variables: PutPricingGroupVariables) => fetchPutPricingGroup({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeletePricingGroupPathParams = {
  /**
   * @format int64
   */
  pricingGroupId: number;
};

export type DeletePricingGroupError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type DeletePricingGroupVariables = {
  pathParams: DeletePricingGroupPathParams;
} & PricerApiContext['fetcherOptions'];

export const fetchDeletePricingGroup = (variables: DeletePricingGroupVariables, signal?: AbortSignal) =>
  pricerApiFetch<undefined, DeletePricingGroupError, undefined, {}, {}, DeletePricingGroupPathParams>({
    url: '/v1/client/pricing-group/{pricingGroupId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeletePricingGroup = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeletePricingGroupError, DeletePricingGroupVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = usePricerApiContext();
  return reactQuery.useMutation<undefined, DeletePricingGroupError, DeletePricingGroupVariables>({
    mutationFn: (variables: DeletePricingGroupVariables) =>
      fetchDeletePricingGroup({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPricingGroupsPathParams = {
  /**
   * @format int64
   */
  pricingGroupId: number;
};

export type GetPricingGroupsError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type GetPricingGroupsVariables = {
  pathParams: GetPricingGroupsPathParams;
} & PricerApiContext['fetcherOptions'];

export const fetchGetPricingGroups = (variables: GetPricingGroupsVariables, signal?: AbortSignal) =>
  pricerApiFetch<Schemas.PricingGroupResponse, GetPricingGroupsError, undefined, {}, {}, GetPricingGroupsPathParams>({
    url: '/v1/client/pricing-group/{pricingGroupId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPricingGroups = <TData = Schemas.PricingGroupResponse>(
  variables: GetPricingGroupsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PricingGroupResponse, GetPricingGroupsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = usePricerApiContext(options);
  return reactQuery.useQuery<Schemas.PricingGroupResponse, GetPricingGroupsError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/client/pricing-group/{pricingGroupId}',
      operationId: 'getPricingGroups',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetPricingGroups({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetPricingGroupsByProductionPathParams = {
  /**
   * @format int64
   */
  productionId: number;
};

export type GetPricingGroupsByProductionError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type GetPricingGroupsByProductionResponse = Schemas.PricingGroupResponse[];

export type GetPricingGroupsByProductionVariables = {
  pathParams: GetPricingGroupsByProductionPathParams;
} & PricerApiContext['fetcherOptions'];

export const fetchGetPricingGroupsByProduction = (
  variables: GetPricingGroupsByProductionVariables,
  signal?: AbortSignal,
) =>
  pricerApiFetch<
    GetPricingGroupsByProductionResponse,
    GetPricingGroupsByProductionError,
    undefined,
    {},
    {},
    GetPricingGroupsByProductionPathParams
  >({
    url: '/v1/client/pricing-group/production/{productionId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPricingGroupsByProduction = <TData = GetPricingGroupsByProductionResponse>(
  variables: GetPricingGroupsByProductionVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetPricingGroupsByProductionResponse, GetPricingGroupsByProductionError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = usePricerApiContext(options);
  return reactQuery.useQuery<GetPricingGroupsByProductionResponse, GetPricingGroupsByProductionError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/client/pricing-group/production/{productionId}',
      operationId: 'getPricingGroupsByProduction',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetPricingGroupsByProduction({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type BulkPatchPricingGroupsError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type BulkPatchPricingGroupsVariables = {
  body?: Schemas.PricingGroupsPatchDto;
} & PricerApiContext['fetcherOptions'];

export const fetchBulkPatchPricingGroups = (variables: BulkPatchPricingGroupsVariables, signal?: AbortSignal) =>
  pricerApiFetch<Schemas.PricingGroupResponse, BulkPatchPricingGroupsError, Schemas.PricingGroupsPatchDto, {}, {}, {}>({
    url: '/v1/client/pricing-group/bulk',
    method: 'patch',
    ...variables,
    signal,
  });

export const useBulkPatchPricingGroups = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PricingGroupResponse,
      BulkPatchPricingGroupsError,
      BulkPatchPricingGroupsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = usePricerApiContext();
  return reactQuery.useMutation<
    Schemas.PricingGroupResponse,
    BulkPatchPricingGroupsError,
    BulkPatchPricingGroupsVariables
  >({
    mutationFn: (variables: BulkPatchPricingGroupsVariables) =>
      fetchBulkPatchPricingGroups({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type BulkDeletePricingGroupsError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type BulkDeletePricingGroupsVariables = {
  body?: Schemas.PricingGroupsDeleteDto;
} & PricerApiContext['fetcherOptions'];

export const fetchBulkDeletePricingGroups = (variables: BulkDeletePricingGroupsVariables, signal?: AbortSignal) =>
  pricerApiFetch<
    Schemas.PricingGroupResponse,
    BulkDeletePricingGroupsError,
    Schemas.PricingGroupsDeleteDto,
    {},
    {},
    {}
  >({
    url: '/v1/client/pricing-group/bulk',
    method: 'delete',
    ...variables,
    signal,
  });

export const useBulkDeletePricingGroups = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PricingGroupResponse,
      BulkDeletePricingGroupsError,
      BulkDeletePricingGroupsVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = usePricerApiContext();
  return reactQuery.useMutation<
    Schemas.PricingGroupResponse,
    BulkDeletePricingGroupsError,
    BulkDeletePricingGroupsVariables
  >({
    mutationFn: (variables: BulkDeletePricingGroupsVariables) =>
      fetchBulkDeletePricingGroups({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PutPricingGroupAdminPathParams = {
  /**
   * @format int64
   */
  pricingGroupId: number;
};

export type PutPricingGroupAdminError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type PutPricingGroupAdminVariables = {
  body?: Schemas.PricingGroupAdminRequest;
  pathParams: PutPricingGroupAdminPathParams;
} & PricerApiContext['fetcherOptions'];

export const fetchPutPricingGroupAdmin = (variables: PutPricingGroupAdminVariables, signal?: AbortSignal) =>
  pricerApiFetch<
    Schemas.PricingGroupResponse,
    PutPricingGroupAdminError,
    Schemas.PricingGroupAdminRequest,
    {},
    {},
    PutPricingGroupAdminPathParams
  >({
    url: '/v1/admin/pricing-groups/{pricingGroupId}',
    method: 'put',
    ...variables,
    signal,
  });

export const usePutPricingGroupAdmin = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PricingGroupResponse,
      PutPricingGroupAdminError,
      PutPricingGroupAdminVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = usePricerApiContext();
  return reactQuery.useMutation<Schemas.PricingGroupResponse, PutPricingGroupAdminError, PutPricingGroupAdminVariables>(
    {
      mutationFn: (variables: PutPricingGroupAdminVariables) =>
        fetchPutPricingGroupAdmin({ ...fetcherOptions, ...variables }),
      ...options,
    },
  );
};

export type PostPricingGroupAdminPathParams = {
  organizationKey: string;
};

export type PostPricingGroupAdminError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type PostPricingGroupAdminVariables = {
  body?: Schemas.PricingGroupRequest;
  pathParams: PostPricingGroupAdminPathParams;
} & PricerApiContext['fetcherOptions'];

export const fetchPostPricingGroupAdmin = (variables: PostPricingGroupAdminVariables, signal?: AbortSignal) =>
  pricerApiFetch<
    Schemas.PricingGroupResponse,
    PostPricingGroupAdminError,
    Schemas.PricingGroupRequest,
    {},
    {},
    PostPricingGroupAdminPathParams
  >({
    url: '/v1/admin/pricing-groups/{organizationKey}',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostPricingGroupAdmin = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PricingGroupResponse,
      PostPricingGroupAdminError,
      PostPricingGroupAdminVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = usePricerApiContext();
  return reactQuery.useMutation<
    Schemas.PricingGroupResponse,
    PostPricingGroupAdminError,
    PostPricingGroupAdminVariables
  >({
    mutationFn: (variables: PostPricingGroupAdminVariables) =>
      fetchPostPricingGroupAdmin({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type DeletePricingGroupAdminPathParams = {
  /**
   * @format int64
   */
  pricingGroupId: number;
};

export type DeletePricingGroupAdminError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type DeletePricingGroupAdminVariables = {
  pathParams: DeletePricingGroupAdminPathParams;
} & PricerApiContext['fetcherOptions'];

export const fetchDeletePricingGroupAdmin = (variables: DeletePricingGroupAdminVariables, signal?: AbortSignal) =>
  pricerApiFetch<undefined, DeletePricingGroupAdminError, undefined, {}, {}, DeletePricingGroupAdminPathParams>({
    url: '/v1/admin/pricing-groups/{pricingGroupId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeletePricingGroupAdmin = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeletePricingGroupAdminError, DeletePricingGroupAdminVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = usePricerApiContext();
  return reactQuery.useMutation<undefined, DeletePricingGroupAdminError, DeletePricingGroupAdminVariables>({
    mutationFn: (variables: DeletePricingGroupAdminVariables) =>
      fetchDeletePricingGroupAdmin({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type MigrateSplitsError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type MigrateSplitsVariables = PricerApiContext['fetcherOptions'];

export const fetchMigrateSplits = (variables: MigrateSplitsVariables, signal?: AbortSignal) =>
  pricerApiFetch<undefined, MigrateSplitsError, undefined, {}, {}, {}>({
    url: '/v1/admin/pricing-groups/migrate-splits',
    method: 'post',
    ...variables,
    signal,
  });

export const useMigrateSplits = (
  options?: Omit<reactQuery.UseMutationOptions<undefined, MigrateSplitsError, MigrateSplitsVariables>, 'mutationFn'>,
) => {
  const { fetcherOptions } = usePricerApiContext();
  return reactQuery.useMutation<undefined, MigrateSplitsError, MigrateSplitsVariables>({
    mutationFn: (variables: MigrateSplitsVariables) => fetchMigrateSplits({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type PostPricingTemplateError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type PostPricingTemplateVariables = {
  body?: Schemas.PricingTemplateCreateRequestDto;
} & PricerApiContext['fetcherOptions'];

export const fetchPostPricingTemplate = (variables: PostPricingTemplateVariables, signal?: AbortSignal) =>
  pricerApiFetch<
    Schemas.PricingTemplateResponseDto,
    PostPricingTemplateError,
    Schemas.PricingTemplateCreateRequestDto,
    {},
    {},
    {}
  >({
    url: '/v1/client/pricing-template',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostPricingTemplate = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.PricingTemplateResponseDto,
      PostPricingTemplateError,
      PostPricingTemplateVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = usePricerApiContext();
  return reactQuery.useMutation<
    Schemas.PricingTemplateResponseDto,
    PostPricingTemplateError,
    PostPricingTemplateVariables
  >({
    mutationFn: (variables: PostPricingTemplateVariables) =>
      fetchPostPricingTemplate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPricingTemplatesQueryParams = {
  SearchText?: string;
  /**
   * @format YYYY-MM-DD
   */
  LastUsedDateStart?: string;
  /**
   * @format YYYY-MM-DD
   */
  LastUsedDateEnd?: string;
  LastModifiedBy?: string;
  /**
   * @format int32
   */
  PageNumber?: number;
  /**
   * @format int32
   */
  PageSize?: number;
  SortBy?: string;
  SortDirection?: string;
  TemplateIds?: number[];
};

export type GetPricingTemplatesError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type GetPricingTemplatesVariables = {
  queryParams?: GetPricingTemplatesQueryParams;
} & PricerApiContext['fetcherOptions'];

export const fetchGetPricingTemplates = (variables: GetPricingTemplatesVariables, signal?: AbortSignal) =>
  pricerApiFetch<
    Schemas.PagedPricingTemplateResponseDto,
    GetPricingTemplatesError,
    undefined,
    {},
    GetPricingTemplatesQueryParams,
    {}
  >({
    url: '/v1/client/pricing-template',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPricingTemplates = <TData = Schemas.PagedPricingTemplateResponseDto>(
  variables: GetPricingTemplatesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PagedPricingTemplateResponseDto, GetPricingTemplatesError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = usePricerApiContext(options);
  return reactQuery.useQuery<Schemas.PagedPricingTemplateResponseDto, GetPricingTemplatesError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/client/pricing-template',
      operationId: 'getPricingTemplates',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetPricingTemplates({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type DeletePricingTemplatePathParams = {
  /**
   * @format int64
   */
  pricingTemplateId: number;
};

export type DeletePricingTemplateError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type DeletePricingTemplateVariables = {
  pathParams: DeletePricingTemplatePathParams;
} & PricerApiContext['fetcherOptions'];

export const fetchDeletePricingTemplate = (variables: DeletePricingTemplateVariables, signal?: AbortSignal) =>
  pricerApiFetch<undefined, DeletePricingTemplateError, undefined, {}, {}, DeletePricingTemplatePathParams>({
    url: '/v1/client/pricing-template/{pricingTemplateId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useDeletePricingTemplate = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, DeletePricingTemplateError, DeletePricingTemplateVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = usePricerApiContext();
  return reactQuery.useMutation<undefined, DeletePricingTemplateError, DeletePricingTemplateVariables>({
    mutationFn: (variables: DeletePricingTemplateVariables) =>
      fetchDeletePricingTemplate({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetPricingTemplatePathParams = {
  /**
   * @format int64
   */
  pricingTemplateId: number;
};

export type GetPricingTemplateError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type GetPricingTemplateVariables = {
  pathParams: GetPricingTemplatePathParams;
} & PricerApiContext['fetcherOptions'];

export const fetchGetPricingTemplate = (variables: GetPricingTemplateVariables, signal?: AbortSignal) =>
  pricerApiFetch<
    Schemas.PricingTemplateSearchResponseDto,
    GetPricingTemplateError,
    undefined,
    {},
    {},
    GetPricingTemplatePathParams
  >({
    url: '/v1/client/pricing-template/{pricingTemplateId}',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPricingTemplate = <TData = Schemas.PricingTemplateSearchResponseDto>(
  variables: GetPricingTemplateVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PricingTemplateSearchResponseDto, GetPricingTemplateError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = usePricerApiContext(options);
  return reactQuery.useQuery<Schemas.PricingTemplateSearchResponseDto, GetPricingTemplateError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/client/pricing-template/{pricingTemplateId}',
      operationId: 'getPricingTemplate',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetPricingTemplate({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetPricingTemplateProductionsPathParams = {
  /**
   * @format int64
   */
  pricingTemplateId: number;
};

export type GetPricingTemplateProductionsQueryParams = {
  /**
   * @format int64
   */
  ProductionId?: number;
  Pricer?: string[];
  NoPricer?: boolean;
  ShowParking?: boolean;
  ShowSpeculation?: boolean;
  ProductionStatus?: Schemas.ProductionStatus[];
  DayOfWeek?: Schemas.IsoDayOfWeek[];
  Filter?: string;
  /**
   * @format YYYY-MM-DD
   */
  StartDate?: string;
  /**
   * @format YYYY-MM-DD
   */
  EndDate?: string;
  IsFloorHit?: boolean;
  IsCeilingHit?: boolean;
  NoComparables?: boolean;
  NoPricingGroup?: boolean;
  ShowAvailableTicketGroups?: boolean;
  /**
   * @format int32
   */
  PageNumber?: number;
  /**
   * @format int32
   */
  PageSize?: number;
  IsWithin90Days?: boolean;
  SortColumn?: Schemas.ProductionRequestSortColumn;
  SortOrder?: Schemas.SortDirection;
  /**
   * @format int64
   */
  MasterVenueId?: number;
};

export type GetPricingTemplateProductionsError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type GetPricingTemplateProductionsVariables = {
  pathParams: GetPricingTemplateProductionsPathParams;
  queryParams?: GetPricingTemplateProductionsQueryParams;
} & PricerApiContext['fetcherOptions'];

export const fetchGetPricingTemplateProductions = (
  variables: GetPricingTemplateProductionsVariables,
  signal?: AbortSignal,
) =>
  pricerApiFetch<
    Schemas.PricingTemplateProductionResponseDtoPagedDto,
    GetPricingTemplateProductionsError,
    undefined,
    {},
    GetPricingTemplateProductionsQueryParams,
    GetPricingTemplateProductionsPathParams
  >({
    url: '/v1/client/pricing-template/{pricingTemplateId}/productions',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPricingTemplateProductions = <TData = Schemas.PricingTemplateProductionResponseDtoPagedDto>(
  variables: GetPricingTemplateProductionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<
      Schemas.PricingTemplateProductionResponseDtoPagedDto,
      GetPricingTemplateProductionsError,
      TData
    >,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = usePricerApiContext(options);
  return reactQuery.useQuery<
    Schemas.PricingTemplateProductionResponseDtoPagedDto,
    GetPricingTemplateProductionsError,
    TData
  >({
    queryKey: queryKeyFn({
      path: '/v1/client/pricing-template/{pricingTemplateId}/productions',
      operationId: 'getPricingTemplateProductions',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetPricingTemplateProductions({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetPricingTemplateInventoryPathParams = {
  /**
   * @format int64
   */
  pricingTemplateId: number;
  /**
   * @format int64
   */
  productionId: number;
};

export type GetPricingTemplateInventoryError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type GetPricingTemplateInventoryResponse = Schemas.PricingTemplateInventoryResponseDto[];

export type GetPricingTemplateInventoryVariables = {
  pathParams: GetPricingTemplateInventoryPathParams;
} & PricerApiContext['fetcherOptions'];

export const fetchGetPricingTemplateInventory = (
  variables: GetPricingTemplateInventoryVariables,
  signal?: AbortSignal,
) =>
  pricerApiFetch<
    GetPricingTemplateInventoryResponse,
    GetPricingTemplateInventoryError,
    undefined,
    {},
    {},
    GetPricingTemplateInventoryPathParams
  >({
    url: '/v1/client/pricing-template/{pricingTemplateId}/productions/{productionId}/inventory',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetPricingTemplateInventory = <TData = GetPricingTemplateInventoryResponse>(
  variables: GetPricingTemplateInventoryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetPricingTemplateInventoryResponse, GetPricingTemplateInventoryError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = usePricerApiContext(options);
  return reactQuery.useQuery<GetPricingTemplateInventoryResponse, GetPricingTemplateInventoryError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/client/pricing-template/{pricingTemplateId}/productions/{productionId}/inventory',
      operationId: 'getPricingTemplateInventory',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetPricingTemplateInventory({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PostChangeProductionAssignmentPathParams = {
  /**
   * @format int64
   */
  productionId: number;
};

export type PostChangeProductionAssignmentError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type PostChangeProductionAssignmentVariables = {
  body?: Schemas.ChangePricerRequest;
  pathParams: PostChangeProductionAssignmentPathParams;
} & PricerApiContext['fetcherOptions'];

export const fetchPostChangeProductionAssignment = (
  variables: PostChangeProductionAssignmentVariables,
  signal?: AbortSignal,
) =>
  pricerApiFetch<
    undefined,
    PostChangeProductionAssignmentError,
    Schemas.ChangePricerRequest,
    {},
    {},
    PostChangeProductionAssignmentPathParams
  >({
    url: '/v1/client/Production/{productionId}/assignment',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostChangeProductionAssignment = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      undefined,
      PostChangeProductionAssignmentError,
      PostChangeProductionAssignmentVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = usePricerApiContext();
  return reactQuery.useMutation<
    undefined,
    PostChangeProductionAssignmentError,
    PostChangeProductionAssignmentVariables
  >({
    mutationFn: (variables: PostChangeProductionAssignmentVariables) =>
      fetchPostChangeProductionAssignment({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetInventoryPathParams = {
  /**
   * @format int64
   */
  productionId: number;
};

export type GetInventoryQueryParams = {
  showParking?: boolean;
  showSpeculation?: boolean;
};

export type GetInventoryError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type GetInventoryResponse = Schemas.InventoryResponseDto[];

export type GetInventoryVariables = {
  pathParams: GetInventoryPathParams;
  queryParams?: GetInventoryQueryParams;
} & PricerApiContext['fetcherOptions'];

export const fetchGetInventory = (variables: GetInventoryVariables, signal?: AbortSignal) =>
  pricerApiFetch<
    GetInventoryResponse,
    GetInventoryError,
    undefined,
    {},
    GetInventoryQueryParams,
    GetInventoryPathParams
  >({
    url: '/v1/client/Production/{productionId}/inventory',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetInventory = <TData = GetInventoryResponse>(
  variables: GetInventoryVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetInventoryResponse, GetInventoryError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = usePricerApiContext(options);
  return reactQuery.useQuery<GetInventoryResponse, GetInventoryError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/client/Production/{productionId}/inventory',
      operationId: 'getInventory',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetInventory({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetProductionPropertiesQueryParams = {
  productionId?: number[];
};

export type GetProductionPropertiesError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type GetProductionPropertiesVariables = {
  queryParams?: GetProductionPropertiesQueryParams;
} & PricerApiContext['fetcherOptions'];

export const fetchGetProductionProperties = (variables: GetProductionPropertiesVariables, signal?: AbortSignal) =>
  pricerApiFetch<
    Schemas.ProductionProperties,
    GetProductionPropertiesError,
    undefined,
    {},
    GetProductionPropertiesQueryParams,
    {}
  >({
    url: '/v1/client/Production/properties',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetProductionProperties = <TData = Schemas.ProductionProperties>(
  variables: GetProductionPropertiesVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.ProductionProperties, GetProductionPropertiesError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = usePricerApiContext(options);
  return reactQuery.useQuery<Schemas.ProductionProperties, GetProductionPropertiesError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/client/Production/properties',
      operationId: 'getProductionProperties',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetProductionProperties({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetInventoryPropertiesByProductionIdPathParams = {
  /**
   * @format int64
   */
  productionId: number;
};

export type GetInventoryPropertiesByProductionIdError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type GetInventoryPropertiesByProductionIdVariables = {
  pathParams: GetInventoryPropertiesByProductionIdPathParams;
} & PricerApiContext['fetcherOptions'];

export const fetchGetInventoryPropertiesByProductionId = (
  variables: GetInventoryPropertiesByProductionIdVariables,
  signal?: AbortSignal,
) =>
  pricerApiFetch<
    Schemas.InventoryProperties,
    GetInventoryPropertiesByProductionIdError,
    undefined,
    {},
    {},
    GetInventoryPropertiesByProductionIdPathParams
  >({
    url: '/v1/client/Production/{productionId}/inventory/properties',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetInventoryPropertiesByProductionId = <TData = Schemas.InventoryProperties>(
  variables: GetInventoryPropertiesByProductionIdVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.InventoryProperties, GetInventoryPropertiesByProductionIdError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = usePricerApiContext(options);
  return reactQuery.useQuery<Schemas.InventoryProperties, GetInventoryPropertiesByProductionIdError, TData>({
    queryKey: queryKeyFn({
      path: '/v1/client/Production/{productionId}/inventory/properties',
      operationId: 'getInventoryPropertiesByProductionId',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetInventoryPropertiesByProductionId({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type PostBulkProductionAssignmentError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type PostBulkProductionAssignmentResponse = Schemas.ProductionAssigmentBulkResponse[];

export type PostBulkProductionAssignmentVariables = {
  body?: Schemas.ProductionAssigmentBulkRequest;
} & PricerApiContext['fetcherOptions'];

export const fetchPostBulkProductionAssignment = (
  variables: PostBulkProductionAssignmentVariables,
  signal?: AbortSignal,
) =>
  pricerApiFetch<
    PostBulkProductionAssignmentResponse,
    PostBulkProductionAssignmentError,
    Schemas.ProductionAssigmentBulkRequest,
    {},
    {},
    {}
  >({
    url: '/v1/client/Production/assignment/bulk',
    method: 'post',
    ...variables,
    signal,
  });

export const usePostBulkProductionAssignment = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      PostBulkProductionAssignmentResponse,
      PostBulkProductionAssignmentError,
      PostBulkProductionAssignmentVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = usePricerApiContext();
  return reactQuery.useMutation<
    PostBulkProductionAssignmentResponse,
    PostBulkProductionAssignmentError,
    PostBulkProductionAssignmentVariables
  >({
    mutationFn: (variables: PostBulkProductionAssignmentVariables) =>
      fetchPostBulkProductionAssignment({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type UpdateProductionAssignmentAdminPathParams = {
  productionAssignmentId: string;
};

export type UpdateProductionAssignmentAdminError = Fetcher.ErrorWrapper<
  | {
      status: 400;
      payload: Schemas.ProblemDetails;
    }
  | {
      status: 404;
      payload: Schemas.ProblemDetails;
    }
>;

export type UpdateProductionAssignmentAdminVariables = {
  body?: Schemas.ProductionAssignmentAdminRequest;
  pathParams: UpdateProductionAssignmentAdminPathParams;
} & PricerApiContext['fetcherOptions'];

export const fetchUpdateProductionAssignmentAdmin = (
  variables: UpdateProductionAssignmentAdminVariables,
  signal?: AbortSignal,
) =>
  pricerApiFetch<
    Schemas.ProductionAssignmentAdminResponse,
    UpdateProductionAssignmentAdminError,
    Schemas.ProductionAssignmentAdminRequest,
    {},
    {},
    UpdateProductionAssignmentAdminPathParams
  >({
    url: '/v1/admin/production-assignments/{productionAssignmentId}',
    method: 'put',
    ...variables,
    signal,
  });

export const useUpdateProductionAssignmentAdmin = (
  options?: Omit<
    reactQuery.UseMutationOptions<
      Schemas.ProductionAssignmentAdminResponse,
      UpdateProductionAssignmentAdminError,
      UpdateProductionAssignmentAdminVariables
    >,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = usePricerApiContext();
  return reactQuery.useMutation<
    Schemas.ProductionAssignmentAdminResponse,
    UpdateProductionAssignmentAdminError,
    UpdateProductionAssignmentAdminVariables
  >({
    mutationFn: (variables: UpdateProductionAssignmentAdminVariables) =>
      fetchUpdateProductionAssignmentAdmin({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type ProductionAssignmentAdminPathParams = {
  productionAssignmentId: string;
};

export type ProductionAssignmentAdminError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type ProductionAssignmentAdminVariables = {
  pathParams: ProductionAssignmentAdminPathParams;
} & PricerApiContext['fetcherOptions'];

export const fetchProductionAssignmentAdmin = (variables: ProductionAssignmentAdminVariables, signal?: AbortSignal) =>
  pricerApiFetch<undefined, ProductionAssignmentAdminError, undefined, {}, {}, ProductionAssignmentAdminPathParams>({
    url: '/v1/admin/production-assignments/{productionAssignmentId}',
    method: 'delete',
    ...variables,
    signal,
  });

export const useProductionAssignmentAdmin = (
  options?: Omit<
    reactQuery.UseMutationOptions<undefined, ProductionAssignmentAdminError, ProductionAssignmentAdminVariables>,
    'mutationFn'
  >,
) => {
  const { fetcherOptions } = usePricerApiContext();
  return reactQuery.useMutation<undefined, ProductionAssignmentAdminError, ProductionAssignmentAdminVariables>({
    mutationFn: (variables: ProductionAssignmentAdminVariables) =>
      fetchProductionAssignmentAdmin({ ...fetcherOptions, ...variables }),
    ...options,
  });
};

export type GetProductionsQueryParams = {
  /**
   * @format int64
   */
  ProductionId?: number;
  Pricer?: string[];
  NoPricer?: boolean;
  ShowParking?: boolean;
  ShowSpeculation?: boolean;
  ProductionStatus?: Schemas.ProductionStatus[];
  DayOfWeek?: Schemas.IsoDayOfWeek[];
  Filter?: string;
  /**
   * @format YYYY-MM-DD
   */
  StartDate?: string;
  /**
   * @format YYYY-MM-DD
   */
  EndDate?: string;
  IsFloorHit?: boolean;
  IsCeilingHit?: boolean;
  NoComparables?: boolean;
  NoPricingGroup?: boolean;
  ShowAvailableTicketGroups?: boolean;
  /**
   * @format int32
   */
  PageNumber?: number;
  /**
   * @format int32
   */
  PageSize?: number;
  IsWithin90Days?: boolean;
  SortColumn?: Schemas.ProductionRequestSortColumn;
  SortOrder?: Schemas.SortDirection;
  /**
   * @format int64
   */
  MasterVenueId?: number;
};

export type GetProductionsError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type GetProductionsVariables = {
  queryParams?: GetProductionsQueryParams;
} & PricerApiContext['fetcherOptions'];

export const fetchGetProductions = (variables: GetProductionsVariables, signal?: AbortSignal) =>
  pricerApiFetch<Schemas.PagedProductionResponseDto, GetProductionsError, undefined, {}, GetProductionsQueryParams, {}>(
    { url: '/v2/client/production', method: 'get', ...variables, signal },
  );

export const useGetProductions = <TData = Schemas.PagedProductionResponseDto>(
  variables: GetProductionsVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<Schemas.PagedProductionResponseDto, GetProductionsError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = usePricerApiContext(options);
  return reactQuery.useQuery<Schemas.PagedProductionResponseDto, GetProductionsError, TData>({
    queryKey: queryKeyFn({
      path: '/v2/client/production',
      operationId: 'getProductions',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetProductions({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetProductionsInPageQueryParams = {
  /**
   * @format int64
   */
  InitialProductionId?: number;
  Pricer?: string[];
  NoPricer?: boolean;
  ShowParking?: boolean;
  ShowSpeculation?: boolean;
  ProductionStatus?: Schemas.ProductionStatus[];
  DayOfWeek?: Schemas.IsoDayOfWeek[];
  Filter?: string;
  /**
   * @format YYYY-MM-DD
   */
  StartDate?: string;
  /**
   * @format YYYY-MM-DD
   */
  EndDate?: string;
  IsFloorHit?: boolean;
  IsCeilingHit?: boolean;
  NoComparables?: boolean;
  NoPricingGroup?: boolean;
  ShowAvailableTicketGroups?: boolean;
  /**
   * @format int32
   */
  PageNumber?: number;
  /**
   * @format int32
   */
  PageSize?: number;
  IsWithin90Days?: boolean;
  SortColumn?: Schemas.ProductionRequestSortColumn;
  SortOrder?: Schemas.SortDirection;
  /**
   * @format int64
   */
  MasterVenueId?: number;
  /**
   * @format int64
   */
  ProductionId?: number;
};

export type GetProductionsInPageError = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type GetProductionsInPageVariables = {
  queryParams?: GetProductionsInPageQueryParams;
} & PricerApiContext['fetcherOptions'];

export const fetchGetProductionsInPage = (variables: GetProductionsInPageVariables, signal?: AbortSignal) =>
  pricerApiFetch<number, GetProductionsInPageError, undefined, {}, GetProductionsInPageQueryParams, {}>({
    url: '/v2/client/production/get-page',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetProductionsInPage = <TData = number>(
  variables: GetProductionsInPageVariables,
  options?: Omit<
    reactQuery.UseQueryOptions<number, GetProductionsInPageError, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = usePricerApiContext(options);
  return reactQuery.useQuery<number, GetProductionsInPageError, TData>({
    queryKey: queryKeyFn({
      path: '/v2/client/production/get-page',
      operationId: 'getProductionsInPage',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetProductionsInPage({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type GetInventoryV2PathParams = {
  /**
   * @format int64
   */
  productionId: number;
};

export type GetInventoryV2QueryParams = {
  showParking?: boolean;
  showSpeculation?: boolean;
};

export type GetInventoryV2Error = Fetcher.ErrorWrapper<{
  status: 400;
  payload: Schemas.ProblemDetails;
}>;

export type GetInventoryV2Response = Schemas.InventoryResponseDtoV2[];

export type GetInventoryV2Variables = {
  pathParams: GetInventoryV2PathParams;
  queryParams?: GetInventoryV2QueryParams;
} & PricerApiContext['fetcherOptions'];

export const fetchGetInventoryV2 = (variables: GetInventoryV2Variables, signal?: AbortSignal) =>
  pricerApiFetch<
    GetInventoryV2Response,
    GetInventoryV2Error,
    undefined,
    {},
    GetInventoryV2QueryParams,
    GetInventoryV2PathParams
  >({
    url: '/v2/client/production/{productionId}/inventory',
    method: 'get',
    ...variables,
    signal,
  });

export const useGetInventoryV2 = <TData = GetInventoryV2Response>(
  variables: GetInventoryV2Variables,
  options?: Omit<
    reactQuery.UseQueryOptions<GetInventoryV2Response, GetInventoryV2Error, TData>,
    'queryKey' | 'queryFn' | 'initialData'
  >,
) => {
  const { fetcherOptions, queryOptions, queryKeyFn } = usePricerApiContext(options);
  return reactQuery.useQuery<GetInventoryV2Response, GetInventoryV2Error, TData>({
    queryKey: queryKeyFn({
      path: '/v2/client/production/{productionId}/inventory',
      operationId: 'getInventoryV2',
      variables,
    }),
    queryFn: ({ signal }) => fetchGetInventoryV2({ ...fetcherOptions, ...variables }, signal),
    ...options,
    ...queryOptions,
  });
};

export type QueryOperation =
  | {
      path: '/v1/Client/ticket-attributes';
      operationId: 'getTicketAttributes';
      variables: GetTicketAttributesVariables;
    }
  | {
      path: '/v1/Client/organization-pricing-setting';
      operationId: 'getOrganizationPricingSetting';
      variables: GetOrganizationPricingSettingVariables;
    }
  | {
      path: '/v1/client/inventory/get-pending';
      operationId: 'getPendingChangesStatus';
      variables: GetPendingChangesStatusVariables;
    }
  | {
      path: '/v1/client/inventory/get-multiple';
      operationId: 'getTicketGroupsByIds';
      variables: GetTicketGroupsByIdsVariables;
    }
  | {
      path: '/v2/client/inventory/get-multiple';
      operationId: 'getTicketGroupsByIdsV2';
      variables: GetTicketGroupsByIdsV2Variables;
    }
  | {
      path: '/v1/client/Marketplace/comparables/{productionId}/properties';
      operationId: 'getMarketplacePropertiesByProductionId';
      variables: GetMarketplacePropertiesByProductionIdVariables;
    }
  | {
      path: '/v1/client/Metrics/event-summary';
      operationId: 'getAllEventSummaries';
      variables: GetAllEventSummariesVariables;
    }
  | {
      path: '/v1/client/Metrics/event/{productionId}';
      operationId: 'getEventSummary';
      variables: GetEventSummaryVariables;
    }
  | {
      path: '/v1/client/org-defaults';
      operationId: 'getOrganizationDetails';
      variables: GetOrganizationDetailsVariables;
    }
  | {
      path: '/v1/client/org-defaults/calculate-defaults/{anchorTicketGroupId}';
      operationId: 'getCalculatedDefaults';
      variables: GetCalculatedDefaultsVariables;
    }
  | {
      path: '/v1/pricer-svc/org-defaults';
      operationId: 'getOrganizationDetailsLegacy';
      variables: GetOrganizationDetailsLegacyVariables;
    }
  | {
      path: '/v1/admin/organization-pricing-setting';
      operationId: 'getOrganizationPricingSettingAdmin';
      variables: GetOrganizationPricingSettingAdminVariables;
    }
  | {
      path: '/v1/client/pricing-group/{pricingGroupId}';
      operationId: 'getPricingGroups';
      variables: GetPricingGroupsVariables;
    }
  | {
      path: '/v1/client/pricing-group/production/{productionId}';
      operationId: 'getPricingGroupsByProduction';
      variables: GetPricingGroupsByProductionVariables;
    }
  | {
      path: '/v1/client/pricing-template';
      operationId: 'getPricingTemplates';
      variables: GetPricingTemplatesVariables;
    }
  | {
      path: '/v1/client/pricing-template/{pricingTemplateId}';
      operationId: 'getPricingTemplate';
      variables: GetPricingTemplateVariables;
    }
  | {
      path: '/v1/client/pricing-template/{pricingTemplateId}/productions';
      operationId: 'getPricingTemplateProductions';
      variables: GetPricingTemplateProductionsVariables;
    }
  | {
      path: '/v1/client/pricing-template/{pricingTemplateId}/productions/{productionId}/inventory';
      operationId: 'getPricingTemplateInventory';
      variables: GetPricingTemplateInventoryVariables;
    }
  | {
      path: '/v1/client/Production/{productionId}/inventory';
      operationId: 'getInventory';
      variables: GetInventoryVariables;
    }
  | {
      path: '/v1/client/Production/properties';
      operationId: 'getProductionProperties';
      variables: GetProductionPropertiesVariables;
    }
  | {
      path: '/v1/client/Production/{productionId}/inventory/properties';
      operationId: 'getInventoryPropertiesByProductionId';
      variables: GetInventoryPropertiesByProductionIdVariables;
    }
  | {
      path: '/v2/client/production';
      operationId: 'getProductions';
      variables: GetProductionsVariables;
    }
  | {
      path: '/v2/client/production/get-page';
      operationId: 'getProductionsInPage';
      variables: GetProductionsInPageVariables;
    }
  | {
      path: '/v2/client/production/{productionId}/inventory';
      operationId: 'getInventoryV2';
      variables: GetInventoryV2Variables;
    };
