import type { PricingGroupRequest, PricingGroupResponse } from '@/api/pricer/pricerApiSchemas';
import i18n from '@/utils/i18n';
import type { Column, ColumnPinningState } from '@tanstack/react-table';
import type { IAnchorTicketGroupModel } from '@/components/inventory/table/IAnchorTicketGroupModel';
import type { IColumnHeaderLabel, PricingGroupRequestExtended } from '@/interfaces/CommonInterfaces';

export const columnIds = {
  selector: 'selector',
  rank: 'rank',
  criteriaGroup: 'pricingCriteriaGroup',
  criteriaName: 'pricingCriteriaName',
  pricingCriteria: 'pricingCriteria',
  section: 'section',
  row: 'row',
  splitRule: 'splitRule',
  quantity: 'quantity',
  seatType: 'seatType',
  startSeat: 'startSeat',
  stockType: 'stockType',
  attributes: 'attributes',
  isInHand: 'isInHand',
  costPrice: 'costPrice',
  listPrice: 'listPrice',
  floor: 'floor',
  ceiling: 'ceiling',
  suggestedPrice: 'suggestedPrice',
  criteria: 'pricingGroup',
  action: 'action',
  ticketGroup: 'ticketGroup',
  threshold: 'threshold',
  noComp: 'isNoActiveCompHit',
  status: 'status',
  actionMenu: 'actionMenu',
  isCeilingHit: 'isCeilingHit',
  isFloorHit: 'isFloorHit',
  optionsFilter: 'optionsFilter',
  isShared: 'isShared',
  isSpeculation: 'isSpeculation',
  pos: 'pos',
  info: 'info',
  internalNotes: 'internalNotes',
  externalNotes: 'externalNotes',
};

type ColumnsIdKeysType = keyof typeof columnIds;
type ColumnLabelsType = Record<ColumnsIdKeysType, IColumnHeaderLabel>;

export const columnLabels: ColumnLabelsType = {
  selector: {
    label: '',
  },
  rank: {
    label: i18n.t('inventory.columns.rank').toUpperCase(),
  },
  criteriaGroup: {
    label: i18n.t('inventory.columns.pricingGroup').toUpperCase(),
  },
  section: {
    label: i18n.t('inventory.columns.section').toUpperCase(),
    tooltip: i18n.t('inventory.columns.section').toUpperCase(),
  },
  row: {
    label: i18n.t('inventory.columns.row').toUpperCase(),
    tooltip: i18n.t('inventory.columns.row').toUpperCase(),
  },
  quantity: {
    label: i18n.t('inventory.columns.quantity').toUpperCase(),
    tooltip: i18n.t('inventory.columns.quantity').toUpperCase(),
  },
  startSeat: {
    label: i18n.t('inventory.columns.seats').toUpperCase(),
    tooltip: i18n.t('inventory.columns.seats').toUpperCase(),
  },
  splitRule: {
    label: i18n.t('inventory.columns.split').toUpperCase(),
    tooltip: i18n.t('inventory.columns.split').toUpperCase(),
  },
  isShared: {
    label: i18n.t('inventory.columns.sharedShort').toUpperCase(),
    tooltip: i18n.t('inventory.columns.shared').toUpperCase(),
  },
  stockType: {
    label: i18n.t('inventory.columns.stock').toUpperCase(),
    tooltip: i18n.t('inventory.columns.stock').toUpperCase(),
  },
  isInHand: {
    label: i18n.t('inventory.columns.inHand').toUpperCase(),
    tooltip: i18n.t('inventory.columns.inHand').toUpperCase(),
  },
  status: {
    label: i18n.t('inventory.columns.status').toUpperCase(),
    tooltip: i18n.t('inventory.columns.status').toUpperCase(),
  },
  costPrice: {
    label: i18n.t('inventory.columns.cost').toUpperCase(),
  },
  listPrice: {
    label: i18n.t('inventory.columns.listPrice').toUpperCase(),
    subLabel: i18n.t('inventory.columns.change').toUpperCase(),
  },
  suggestedPrice: {
    label: i18n.t('inventory.columns.suggestedPrice').toUpperCase(),
  },
  action: {
    label: i18n.t('inventory.columns.action').toUpperCase(),
  },
  actionMenu: {
    label: '',
  },
  ticketGroup: {
    label: i18n.t('inventory.columns.ticketGroup').toUpperCase(),
    tooltip: i18n.t('inventory.columns.ticketGroup').toUpperCase(),
  },
  attributes: {
    label: i18n.t('inventory.columns.attributes').toUpperCase(),
    tooltip: i18n.t('inventory.columns.attributes').toUpperCase(),
  },

  seatType: {
    label: 'seatType',
  },
  threshold: {
    label: 'threshold',
  },
  noComp: {
    label: 'noComp',
  },
  ceiling: {
    label: i18n.t('inventory.columns.ceiling').toUpperCase(),
    tooltip: i18n.t('inventory.columns.ceiling').toUpperCase(),
  },
  floor: {
    label: i18n.t('inventory.columns.floor').toUpperCase(),
    tooltip: i18n.t('inventory.columns.floor').toUpperCase(),
  },
  pos: {
    label: i18n.t('inventory.columns.pos').toUpperCase(),
    tooltip: i18n.t('inventory.columns.pos').toUpperCase(),
  },
  criteria: {
    label: 'criteria',
  },
  pricingCriteria: {
    label: 'pricingCriteria',
  },

  isCeilingHit: {
    label: 'isCeilingHit',
  },
  isFloorHit: {
    label: 'isFloorHit',
  },
  optionsFilter: {
    label: 'optionsFilter',
  },
  isSpeculation: {
    label: 'isSpeculation',
  },
  criteriaName: {
    label: 'criteriaName',
  },

  info: {
    label: i18n.t('inventory.columns.info').toUpperCase(),
    tooltip: i18n.t('inventory.columns.info').toUpperCase(),
  },
  internalNotes: {
    label: i18n.t('inventory.columns.internalNotes').toUpperCase(),
    tooltip: i18n.t('inventory.columns.internalNotes').toUpperCase(),
  },
  externalNotes: {
    label: i18n.t('inventory.columns.externalNotes').toUpperCase(),
    tooltip: i18n.t('inventory.columns.externalNotes').toUpperCase(),
  },
};

columnLabels as Record<string, { label: string; subLabel: string }>;

//These are columns used in the inventory table but are not surface to the user at all or in a default way
export const serviceColumns = [
  columnIds.selector,
  columnIds.criteriaGroup,
  columnIds.actionMenu,
  columnIds.action,
  columnIds.optionsFilter,
  columnIds.isSpeculation,
  columnIds.seatType,
];

export const getColumnDefaultVisibility = (posCount: number) => {
  return {
    [columnIds.isInHand]: false,
    [columnIds.floor]: false,
    [columnIds.ceiling]: false,
    [columnIds.action]: false,
    [columnIds.optionsFilter]: false,
    [columnIds.isSpeculation]: false,
    [columnIds.splitRule]: false,
    [columnIds.stockType]: false,
    [columnIds.seatType]: false,
    [columnIds.status]: false,
    [columnIds.pos]: posCount > 1,
    [columnIds.attributes]: false,
    [columnIds.info]: false,
    [columnIds.externalNotes]: false,
    [columnIds.internalNotes]: false,
  };
};

export const defaultSortingColumns = [{ id: columnIds.section, desc: false }];

export const isPricingGroupResponse = (
  key: keyof PricingGroupResponse,
  item: PricingGroupResponse | PricingGroupRequestExtended,
): boolean => {
  return item && key in item;
};

export const getPricingGroupsMap = (
  pricingGroups: PricingGroupResponse[] | undefined,
): Map<number, PricingGroupResponse> => {
  return new Map(pricingGroups?.map((item) => [item.id ?? 0, item]));
};

export type PricingGroupHighlightType =
  | 'autoPricingNoWarnings'
  | 'autoPricingWithWarnings'
  | 'simulation'
  | 'createOrEditPricingGroup';

export function getHighlightType({
  pricingGroup,
  isCreateOrEditPricingGroup,
  pricingGroupInfo,
}: {
  pricingGroup: PricingGroupResponse | PricingGroupRequest | undefined;
  isCreateOrEditPricingGroup?: boolean | null;
  pricingGroupInfo?: IAnchorTicketGroupModel;
}): PricingGroupHighlightType {
  if (isCreateOrEditPricingGroup) {
    return 'createOrEditPricingGroup';
  }
  if (!pricingGroup) {
    return 'simulation';
  }
  let highlightWarning = false;
  let isAutoPricingEnabled;
  if (isPricingGroupResponse('pricingGroupTickets', pricingGroup)) {
    isAutoPricingEnabled = (pricingGroup as PricingGroupResponse).isAutoPricingEnabled;
    highlightWarning = pricingGroupInfo?.hasWarnings ?? false;
  } else {
    isAutoPricingEnabled = (pricingGroup as PricingGroupRequest).pricingGroupRequestType === 'AutoPrice';
  }

  let highlightType: PricingGroupHighlightType = 'simulation';
  if (isAutoPricingEnabled) {
    if (highlightWarning) {
      highlightType = 'autoPricingWithWarnings';
    } else {
      highlightType = 'autoPricingNoWarnings';
    }
  }

  return highlightType;
}

declare module '@tanstack/react-table' {
  interface InventoryTableMeta {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
  }
}

export function getColumnCanGlobalFilter(column: Column<any>) {
  return column.id === 'section' || column.id === 'ticketGroup';
}

export const InventoryPageEvents = {
  changePriorityDisplay: 'changePriorityDisplay',
  scrollToActivePG: 'scrollToActivePG',
};

export const DeletedTicketsPricingGroupFakeTicketId = -100;

export const AnchorRank = 0;

export const DefaultPinning: ColumnPinningState = {
  right: [columnIds.actionMenu],
};

export function mergePinningWidthDefault(
  pinning: ColumnPinningState,
  defaultPining: ColumnPinningState,
): ColumnPinningState {
  const left = pinning.left;
  const right = pinning.right;
  return {
    left: [...(defaultPining.left || []), ...(left || [])],
    right: [...(right || []), ...(defaultPining.right || [])],
  };
}
