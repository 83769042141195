import type { IOrganizationAppRequest } from '@/interfaces/OrganizationsApp/IOrganizationAppRequest';
import type { INotification } from '@/interfaces/CommonInterfaces';
import { useMutation } from '@tanstack/react-query';
import * as React from 'react';
import { apiEndpoints, putAuthAPI } from '@/utils/api';
import i18n from '@/utils/i18n';
import { getErrorNotification, getLoadingNotification, getSuccessNotification } from '@/utils/notifications';
import { useNotificationsApi } from '../notifications/context-hooks';
import type { IOrganizationApi } from './context-hooks';
import { OrganizationApiContext, OrganizationContext } from './context-hooks';
import { usePrevious } from '@/hooks';
import { useGetOrganizationDetails, useUpdateOrganizationDefaults } from '@/api/pricer/pricerApiComponents';
import { useMemo, useReducer } from 'react';
import { reducer } from './reducers/organizationDefaultsReducer';
import type { MarketCriteriaDefaultsDto, OrganizationPricingDefaultDto } from '@/api/pricer/pricerApiSchemas';
import type { PricingDefaultsDto } from '@/api/pricer/pricerApiSchemas';
import { EnableDisabledType, RequiredOptionType, ShareUnshareType } from '@/utils/constants';
import { autoPriceSimulateOption } from '../pricing-mode/constants';
import { formatCurrencyInput } from '@/utils/helpers';
import { INITIAL_STATE, INITIAL_STATE_MARKET_DEFAULT } from './constants';
import { HitOrgDefaultPricingMethod } from '@/api/pricer/pricerApiSchemaExtensions';
import useGetOrganization from '@/hooks/organization/useGetOrganization';
import type { ClientUserAppResponse, OrganizationAppResponse } from '@/api/clientService/clientServiceApiSchemas';
import useGetOrganizationUsers from '@/hooks/organization/useGetOrganizationUsers';
import { marketDefaultReducer } from './reducers/marketComparablesReducer';

function OrganizationContextProvider(props: React.PropsWithChildren<{}>) {
  const [orgDefaultState, orgDefaultDispatch] = useReducer(reducer, INITIAL_STATE);
  const [marketDefaultState, marketDefaultDispatch] = useReducer(marketDefaultReducer, INITIAL_STATE_MARKET_DEFAULT);
  const { pushNotification } = useNotificationsApi();

  const organizationQuery = useGetOrganization();

  const { mutate, isSuccess, isError, reset, error } = useMutation({
    mutationFn: (req: { orgRequest: IOrganizationAppRequest; notification: INotification }) => {
      const { notification, orgRequest } = req;
      pushNotification(notification);
      return putAuthAPI(apiEndpoints.organization, orgRequest);
    },
    onSuccess: (response, variables) => {
      const { notification } = variables;
      pushNotification(
        getSuccessNotification(
          notification.id,
          i18n.t('organization.notifications.success.title'),
          i18n.t('organization.notifications.success.message'),
          true,
        ),
      );
    },
    onError: (error, variables) => {
      const { notification } = variables;
      pushNotification(
        getErrorNotification(notification.id, error, true, i18n.t('organization.notifications.error.title')),
      );
    },
  });

  const { usersData, isUsersLoading, isUsersFetching, usersLoadSuccess, refetchUsers } = useGetOrganizationUsers();

  const prevMutateSuccess = usePrevious(isSuccess);
  React.useEffect(() => {
    if (isSuccess && !prevMutateSuccess) {
      organizationQuery.refetchOrganization();
    }
  }, [isSuccess]);

  const update = (organization: OrganizationAppResponse) => {
    reset();
    const { name, address1, address2, city, state, postalCode, country, phone, email, knownAs, primaryContact } =
      organization;
    const orgRequest = {
      name,
      address1,
      address2,
      city,
      state,
      postalCode,
      country,
      phone,
      email,
      knownAs,
      primaryContact,
    } as IOrganizationAppRequest;

    const notification = getLoadingNotification(
      'orgInfoUpdate',
      i18n.t('organization.notifications.applying.title'),
      i18n.t('organization.notifications.applying.message'),
    );
    mutate({ orgRequest, notification });
  };

  const users = React.useMemo(() => {
    if (usersLoadSuccess && usersData && Array.isArray(usersData)) {
      return usersData.sort((a: ClientUserAppResponse, b: ClientUserAppResponse) => {
        const aName = a?.name?.toLowerCase();
        const bName = b?.name?.toLowerCase();
        return !aName ? -1 : !bName ? 1 : aName > bName ? 1 : -1;
      });
    }
    return [];
  }, [usersData]);

  const {
    data: orgDefaults,
    isPending: isOrgDefaultsPending,
    refetch: refetchOrgDefaults,
  } = useGetOrganizationDetails({});

  const { mutate: updateOrgDefaults } = useUpdateOrganizationDefaults({
    onMutate: () => {
      const notification = getLoadingNotification(
        'orgDefaultsUpdate',
        i18n.t('pricingSettings.notifications.applying.title'),
        i18n.t('pricingSettings.notifications.applying.message'),
      );
      pushNotification(notification);
      return { notification: notification };
    },
    onSuccess: (orgDefault, variables, context) => {
      const { notification } = context as { notification: INotification };
      pushNotification(
        getSuccessNotification(
          notification.id,
          i18n.t('pricingSettings.notifications.success.title'),
          i18n.t('pricingSettings.notifications.success.message'),
          true,
        ),
      );
      refetchOrgDefaults();
    },
    onError: (error, variables, context) => {
      const { notification } = context as { notification: INotification };
      pushNotification(
        getErrorNotification(notification.id, error, true, i18n.t('pricingSettings.notifications.error.title')),
      );
    },
  });

  const handleSaveOrgDefaults = (orgDefault: typeof INITIAL_STATE) => {
    const {
      ceilingRequired,
      ceilingValue,
      ceilingUnit,
      floorValue,
      floorUnit,
      adjustmentAmount,
      unit,
      adjustment,
      groupAdjustmentAmount,
      groupUnit,
      groupAdjustment,
      shareAutoPriceEnabled,
      shareAnchor,
      shareTicketGroups,
      shareTicketGroupsUnit,
      shareTicketGroupsAmount,
      advanceFloorRadio,
      advanceCeilingRadio,
      advanceNoCompsRadio,
      pushPosAutoButton,
      floorOptionToggle,
      ceilingOptionToggle,
      shareTicketGroupsActive,
      splitsDynamic,
      splitsEnabled,
    } = orgDefault;

    const orgDefaultsRequest: OrganizationPricingDefaultDto = {
      ...(ceilingRequired?.key && {
        isCeilingRequired: ceilingRequired.key === RequiredOptionType.REQUIRED,
      }),
      pricingDefaults: {
        ...(ceilingValue.value && ceilingOptionToggle && { ceiling: formatCurrencyInput(ceilingValue.value) }),
        ...(ceilingUnit?.key && ceilingOptionToggle && { ceilingType: ceilingUnit?.key }),
        ...(floorValue && floorOptionToggle && { floor: formatCurrencyInput(floorValue.value) }),
        ...(floorUnit?.key && floorOptionToggle && { floorType: floorUnit?.key }),
        ...(adjustmentAmount.value && { increment: formatCurrencyInput(adjustmentAmount.value) }),
        ...(unit?.key && { incrementType: unit?.key }),
        ...(adjustment?.key && { incrementDirection: adjustment?.key }),
        ...(groupAdjustmentAmount.value && { groupOffset: formatCurrencyInput(groupAdjustmentAmount.value) }),
        ...(groupUnit?.key && { groupOffsetType: groupUnit?.key }),
        ...(groupAdjustment?.key && { groupOffsetDirection: groupAdjustment?.key }),
        ...(shareAutoPriceEnabled?.key && {
          isShareOptionEnabled: shareAutoPriceEnabled.key === EnableDisabledType.ENABLED,
        }),
        ...(shareAnchor?.key && { shareAnchor: shareAnchor.key === ShareUnshareType.SHARE }),
        ...(shareTicketGroups && { shareOptionsType: shareTicketGroups.key?.toLowerCase() }),
        ...(shareTicketGroupsUnit?.key &&
          shareTicketGroupsActive && { customSharingMethod: shareTicketGroupsUnit?.key }),
        ...(shareTicketGroupsAmount &&
          shareTicketGroupsActive && { customSharingAmount: Number(shareTicketGroupsAmount.value) }),
        ...(pushPosAutoButton && {
          pricingGroupRequestType: autoPriceSimulateOption.find((option) => option.key === pushPosAutoButton.key)?.key,
        }),
        ...(advanceFloorRadio?.key &&
          advanceFloorRadio.key !== HitOrgDefaultPricingMethod && {
            floorPricingMethod: advanceFloorRadio.key,
          }),
        ...(advanceCeilingRadio?.key &&
          advanceCeilingRadio.key !== HitOrgDefaultPricingMethod && {
            ceilingPricingMethod: advanceCeilingRadio.key,
          }),
        ...(advanceNoCompsRadio?.key &&
          advanceNoCompsRadio.key !== HitOrgDefaultPricingMethod && {
            noCompPricingMethod: advanceNoCompsRadio.key,
          }),
      } as PricingDefaultsDto,
      marketCriteriaDefaults: {
        ...(splitsEnabled?.key && {
          isSplitsEnabled: splitsEnabled.key === EnableDisabledType.ENABLED,
        }),
        ...(splitsDynamic?.key && {
          isSplitsDynamic: splitsDynamic.key === EnableDisabledType.ENABLED,
        }),
      } as MarketCriteriaDefaultsDto,
    };
    updateOrgDefaults({ body: orgDefaultsRequest });
  };

  const handleSaveMarketDefaults = (marketDefault: typeof INITIAL_STATE_MARKET_DEFAULT) => {
    const { splitsDynamicToggle, splitsEnabled } = marketDefault;
    const marketDefaultsRequest: MarketCriteriaDefaultsDto = {
      isSplitsEnabled: splitsEnabled?.key === EnableDisabledType.ENABLED,
      isSplitsDynamic: splitsDynamicToggle,
    };
    updateOrgDefaults({ body: { marketCriteriaDefaults: marketDefaultsRequest } });
  };

  const api: IOrganizationApi = useMemo(
    () => ({
      orgDefaultDispatch,
      marketDefaultDispatch,
      handleSaveOrgDefaults,
      refetchOrgDefaults,
      handleSaveMarketDefaults,
    }),
    [],
  );

  return (
    <OrganizationContext.Provider
      value={{
        organization: organizationQuery.organization,
        update,
        isError,
        error,
        isOrganizationLoading: organizationQuery.isOrganizationLoading,
        users,
        usersLoadSuccess,
        refetchUsers,
        isUsersLoading,
        isUsersFetching,
        orgDefaultState,
        orgDefaults,
        marketDefaultState,
      }}
      {...props}
    >
      <OrganizationApiContext.Provider value={api}>{props.children}</OrganizationApiContext.Provider>
    </OrganizationContext.Provider>
  );
}

export { OrganizationContextProvider };
