/* eslint-disable max-lines */
import type * as Schemas from '@/api/pricer/pricerApiSchemas';
import type {
  InventoryResponseDtoV2,
  PricingGroupRequest,
  PricingGroupResponse,
  UpdateTicketGroupRequestDto,
} from '@/api/pricer/pricerApiSchemas';
import type {
  IInventoryFilters,
  IMarketFilters,
  IOptionItem,
  PricingGroupRequestExtended,
} from '@/interfaces/CommonInterfaces';
import type { ActionsSlice, ImmerStateCreator } from '@/utils/constants';
import type { InventoryState } from '@/stores/inventory/inventory-store';
import { getComparablesFromFilters, marketplaceFiltersBase } from '@/context/pricing-mode/filter-constants';
import type { ProductionMapGroup, ProductionSection } from '@/components/SeatMap/type';
import type { IAnchorTicketGroupModel } from '@/components/inventory/table/IAnchorTicketGroupModel';
import { inventoryFiltersBase } from '@/context/inventory/constants';
import { trackComparablesUsage } from '@/stores/inventory/store-utils';

export type MainState = {
  // from pricing mode context
  selectedProduction: Schemas.ProductionResponseDto | null;
  activePricingGroup: PricingGroupRequestExtended | null;
  invalidTicketGroupIds: number[];
  marketFilters: IMarketFilters;
  pgTicketsPendingChanges: number[];
  mapZones: ProductionMapGroup[];
  mapSections: ProductionSection[];
  compSections: string[];
  selectedZoneIds: number[];
  hasOverlap: boolean;
  // from inv context
  committedUnsettledChanges: number[];
  inventoryFilters: IInventoryFilters;
  filteredTicketIds: number[];
  hoverPricingGroupId: number | null;
  processedInventory: InventoryResponseDtoV2[];
  pricingGroupsAnchors: Map<number, IAnchorTicketGroupModel>;
  pricingGroupsMap: Map<number, PricingGroupResponse | PricingGroupRequest>;
  posUpdateRequests: Map<string, UpdateTicketGroupRequestDto>;
  toBeUpdated: Map<string, UpdateTicketGroupRequestDto>;
  delay: number | null;
  posSettledChanges: number[];
  hideZonesInMenu: boolean;
  localRowRange: string;
  quantityFilter: IInventoryFilters['quantity'];
  auditIsOpenForPricingGroupId: number | null;
  auditIsOpenForTicketGroupId: number | null;
};

type MainActions = {
  setSelectedProduction: (production: Schemas.ProductionResponseDto) => void;
  setActivePricingGroup: (pricingGroup: PricingGroupRequestExtended | null) => void;
  setInvalidTicketGroupIds: (ids: number[]) => void;
  setMarketFilters: (filters: IMarketFilters) => void;
  setMarketFiltersZonesOptions: (options: IOptionItem<string>[]) => void;
  setMarketFiltersSectionsOptions: (options: IOptionItem<string>[]) => void;
  setPgTicketsPendingChanges: (ids: number[]) => void;
  setMapZones: (zones: ProductionMapGroup[]) => void;
  setMapSections: (mapSections: ProductionSection[]) => void;
  setCompSections: (compSections: string[]) => void;
  setSelectedZoneIds: (zoneIds: number[]) => void;
  setHasOverlap: (hasOverlap: boolean) => void;
  setProcessedInventory: (inventory: InventoryResponseDtoV2[]) => void;
  setHoverPricingGroupId: (pricingGroupId: number | null) => void;
  setPricingGroupsAnchors: (pricingGroupsAnchors: Map<number, IAnchorTicketGroupModel>) => void;
  setPricingGroupsMap: (pricingGroupsMap: Map<number, PricingGroupResponse | PricingGroupRequest>) => void;
  setCommittedUnsettledChanges: (changes: number[]) => void;
  setInventoryFilters: (filters: IInventoryFilters) => void;
  setFilteredTicketIds: (filteredTicketIds: number[]) => void;
  setPosUpdateRequests: (posUpdateRequests: Map<string, UpdateTicketGroupRequestDto>) => void;
  appendPosUpdateRequest: (requestIdForTGShare: string, value: UpdateTicketGroupRequestDto) => void;
  setToBeUpdated: (toBeUpdated: Map<string, UpdateTicketGroupRequestDto>) => void;
  setDelay: (delay: number | null) => void;
  setPosSettledChanges: (changes: number[]) => void;
  setHideZonesInMenu: (hide: boolean) => void;
  updateRowRange: (range: string) => void;
  updateOutliers: (outliers: string) => void;
  setQuantityFilter: () => void;
  setAuditIsOpenForPricingGroupId: (pricingGroupId: number | null) => void;
  setAuditIsOpenForTicketGroupId: (ticketGroupId: number | null) => void;
};

export type MainSliceState = MainState & ActionsSlice<MainActions>;

export const createMainSlice: ImmerStateCreator<InventoryState, MainSliceState> = (set, get, mutate) => ({
  activePricingGroup: null,
  selectedProduction: null,
  invalidTicketGroupIds: [],
  pgTicketsPendingChanges: [],
  marketFilters: marketplaceFiltersBase,
  mapZones: [],
  mapSections: [],
  compSections: [],
  selectedZoneIds: marketplaceFiltersBase.zones.data.options
    .filter((zone) => zone.selected)
    .map((zone) => Number(zone.value)),
  hasOverlap: false,
  committedUnsettledChanges: [],
  inventoryFilters: inventoryFiltersBase,
  filteredTicketIds: [],
  hoverPricingGroupId: null,
  processedInventory: [],
  pricingGroupsAnchors: new Map(),
  pricingGroupsMap: new Map(),
  posUpdateRequests: new Map(),
  toBeUpdated: new Map(),
  delay: null,
  posSettledChanges: [],
  // Initialize new properties
  hideZonesInMenu: false,
  localRowRange: '',

  zoneSections: undefined,
  auditIsOpenForPricingGroupId: null,
  quantityFilter: inventoryFiltersBase.quantity,
  auditIsOpenForTicketGroupId: null,
  actions: {
    setSelectedProduction: (production) => {
      set(
        (state) => {
          if (state.mainSlice.selectedProduction !== production) {
            state.mainSlice.selectedProduction = production;
            state.mainSlice.activePricingGroup = null;
          }
        },
        false,
        {
          type: 'inv:main/setSelectedProduction',
        },
      );
      get().selectionModeSlice.actions.resetSelectionsForTicketsAndPricingGroups();
    },
    setActivePricingGroup: (pricingGroup) => {
      set(
        (state) => {
          if (state.mainSlice.activePricingGroup !== pricingGroup) {
            state.mainSlice.activePricingGroup = pricingGroup;
          }
        },
        false,
        {
          type: 'inv:main/setActivePricingGroup',
        },
      );
      get().selectionModeSlice.actions.updateSelectionModeBasedOnActivePricingGroup();
    },
    setInvalidTicketGroupIds: (ids: number[]) =>
      set(
        (state) => {
          state.mainSlice.invalidTicketGroupIds = ids;
        },
        false,
        {
          type: 'inv:main/setInvalidTicketGroupIds',
        },
      ),

    setProcessedInventory: (inventory: InventoryResponseDtoV2[]) =>
      set(
        (state) => {
          state.mainSlice.processedInventory = inventory;
        },
        false,
        {
          type: 'inv:main/setProcessedInventory',
        },
      ),
    setHoverPricingGroupId: (pricingGroupId: number | null) =>
      set(
        (state) => {
          state.mainSlice.hoverPricingGroupId = pricingGroupId;
        },
        false,
        {
          type: 'inv:main/setHoverPricingGroupId',
        },
      ),
    setPricingGroupsAnchors: (pricingGroupsAnchors: Map<number, IAnchorTicketGroupModel>) =>
      set(
        (state) => {
          state.mainSlice.pricingGroupsAnchors = pricingGroupsAnchors;
        },
        false,
        {
          type: 'inv:main/setPricingGroupsAnchors',
        },
      ),
    setPricingGroupsMap: (pricingGroupsMap: Map<number, PricingGroupResponse | PricingGroupRequest>) =>
      set(
        (state) => {
          state.mainSlice.pricingGroupsMap = pricingGroupsMap;
        },
        false,
        {
          type: 'inv:main/setPricingGroupsMap',
        },
      ),
    setCommittedUnsettledChanges: (changes: number[]) =>
      set(
        (state) => {
          state.mainSlice.committedUnsettledChanges = changes;
        },
        false,
        {
          type: 'inv:main/setCommittedUnsettledChanges',
        },
      ),
    setInventoryFilters: (filters: IInventoryFilters) => {
      set(
        (state) => {
          state.mainSlice.inventoryFilters = filters;
        },
        false,
        {
          type: 'inv:main/setInventoryFilters',
        },
      );
      get().mainSlice.actions.setQuantityFilter();
    },
    setFilteredTicketIds: (filteredTicketIds: number[]) => {
      set(
        (state) => {
          state.mainSlice.filteredTicketIds = filteredTicketIds;
        },
        false,
        {
          type: 'inv:main/setFilteredTicketIds',
        },
      );
      get().mainSlice.actions.setQuantityFilter();
    },

    setPgTicketsPendingChanges: (ids: number[]) =>
      set(
        (state) => {
          state.mainSlice.pgTicketsPendingChanges = ids;
          if (!state.mainSlice.pgTicketsPendingChanges.length) {
            return;
          }
          state.mainSlice.committedUnsettledChanges = [
            ...state.mainSlice.committedUnsettledChanges,
            ...state.mainSlice.pgTicketsPendingChanges,
          ];
          state.mainSlice.pgTicketsPendingChanges = [];
        },
        false,
        {
          type: 'inv:main/setPgTicketsPendingChanges',
        },
      ),
    setMapZones: (zones) =>
      set(
        (state) => {
          state.mainSlice.mapZones = zones;
        },
        false,
        {
          type: 'inv:main/setMapZones',
        },
      ),
    setMapSections: (mapSections: ProductionSection[]) =>
      set(
        (state) => {
          state.mainSlice.mapSections = mapSections;
        },
        false,
        {
          type: 'inv:main/setMapSections',
        },
      ),
    setCompSections: (compSections: string[]) =>
      set(
        (state) => {
          state.mainSlice.compSections = compSections;
        },
        false,
        {
          type: 'inv:main/setCompSections',
        },
      ),
    setSelectedZoneIds: (zoneIds: number[]) =>
      set(
        (state) => {
          state.mainSlice.selectedZoneIds = zoneIds;
        },
        false,
        {
          type: 'inv:main/setSelectedZoneIds',
        },
      ),
    setHasOverlap: (hasOverlap: boolean) =>
      set(
        (state) => {
          state.mainSlice.hasOverlap = hasOverlap;
        },
        false,
        {
          type: 'inv:main/setHasOverlap',
        },
      ),
    setPosUpdateRequests: (posUpdateRequests: Map<string, UpdateTicketGroupRequestDto>) =>
      set(
        (state) => {
          state.mainSlice.posUpdateRequests = posUpdateRequests;
        },
        false,
        {
          type: 'inv:main/setPosUpdateRequests',
        },
      ),
    appendPosUpdateRequest: (requestIdForTGShare: string, value: UpdateTicketGroupRequestDto) =>
      set(
        (state) => {
          state.mainSlice.posUpdateRequests.set(requestIdForTGShare, value);
        },
        false,
        {
          type: 'inv:main/appendPosUpdateRequest',
        },
      ),
    setMarketFilters: (filters: IMarketFilters) =>
      set(
        (state) => {
          trackComparablesUsage(state.mainSlice.selectedProduction, getComparablesFromFilters(filters));

          state.mainSlice.marketFilters = filters;
          // update selected zones when setting the filters
          state.mainSlice.selectedZoneIds = filters.zones.data.options
            .filter((zone) => zone.selected)
            .map((zone) => Number(zone.value));
        },
        false,
        {
          type: 'inv:main/setFilters',
        },
      ),

    setMarketFiltersZonesOptions: (options: IOptionItem<string>[]) =>
      set(
        (state) => {
          state.mainSlice.marketFilters.zones.data.options = options;
        },
        false,
        {
          type: 'inv:main/setMarketFiltersZonesOptions',
        },
      ),
    setMarketFiltersSectionsOptions: (options: IOptionItem<string>[]) =>
      set(
        (state) => {
          state.mainSlice.marketFilters.sections.data.options = options;
        },
        false,
        {
          type: 'inv:main/setMarketFiltersSectionsOptions',
        },
      ),

    setToBeUpdated: (toBeUpdated: Map<string, UpdateTicketGroupRequestDto>) =>
      set(
        (state) => {
          state.mainSlice.toBeUpdated = toBeUpdated;
        },
        false,
        {
          type: 'inv:main/setToBeUpdated',
        },
      ),
    setDelay: (delay: number | null) =>
      set(
        (state) => {
          state.mainSlice.delay = delay;
        },
        false,
        {
          type: 'inv:main/setDelay',
        },
      ),
    setPosSettledChanges: (changes: number[]) =>
      set(
        (state) => {
          state.mainSlice.posSettledChanges = changes;
        },
        false,
        {
          type: 'inv:main/setPosSettledChanges',
        },
      ),
    // New methods to update the new properties
    setHideZonesInMenu: (hide: boolean) => {
      set(
        (state) => {
          state.mainSlice.hideZonesInMenu = hide;
        },
        false,
        {
          type: 'inv:main/setHideZonesInMenu',
        },
      );
    },
    updateRowRange: (range: string) => {
      const isARange = range.includes('-');
      const sequenceOption = isARange ? '' : range;
      const rowRangeOption = isARange ? range : '';
      set(
        (state) => {
          state.mainSlice.marketFilters.rowSequence!.data.option = sequenceOption;
          state.mainSlice.marketFilters.rowRange!.data.option = rowRangeOption;
        },
        false,
        {
          type: 'inv:main/updateRowRange',
        },
      );
    },
    updateOutliers: (outliers: string) => {
      set(
        (state) => {
          state.mainSlice.marketFilters.lowerOutlierAmount.data.option = outliers;
        },
        false,
        {
          type: 'inv:main/updateOutliers',
        },
      );
    },
    setQuantityFilter: () => {
      const { processedInventory, filteredTicketIds, inventoryFilters } = get().mainSlice;

      const filteredInventory = processedInventory.filter((item) => {
        return item.ticketGroupId !== undefined && filteredTicketIds.includes(item.ticketGroupId);
      });

      const quantities = filteredInventory.map((item: InventoryResponseDtoV2) => item.quantity ?? 0);
      const uniqueSortedQuantities = Array.from(new Set(quantities)).sort((a, b) => Number(a) - Number(b));

      const previousSelectedOptions = inventoryFilters.quantity.data.options || [];

      const data = uniqueSortedQuantities.map((quantity: number) => {
        const isSelected = previousSelectedOptions.some((option) => option.value === quantity && option.selected);

        return {
          label: quantity.toString(),
          value: quantity,
          selected: isSelected,
        };
      });

      set(
        (state) => {
          state.mainSlice.quantityFilter = {
            type: inventoryFilters.quantity.type,
            data: { ...inventoryFilters.quantity.data, options: data },
          };
        },
        false,
        {
          type: 'inv:main/setQuantityFilter',
        },
      );
    },
    setAuditIsOpenForPricingGroupId: (pricingGroupId) =>
      set(
        ({ mainSlice }) => {
          mainSlice.auditIsOpenForPricingGroupId = pricingGroupId;
        },
        false,
        {
          type: 'inv:main/setAuditIsOpenForPricingGroupId',
        },
      ),

    setAuditIsOpenForTicketGroupId: (ticketGroupId) =>
      set(
        ({ mainSlice }) => {
          mainSlice.auditIsOpenForTicketGroupId = ticketGroupId;
        },
        false,
        {
          type: 'inv:main/setAuditIsOpenForTicketGroupId',
        },
      ),
  },
});
