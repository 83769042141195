/* eslint-disable max-lines */
import requestError from '@/utils/i18n/requestError';
import market from '@/utils/i18n/market';
import inventory from '@/utils/i18n/inventory';
import pricingApplyTemplatePage from '@/utils/i18n/pricingApplyTemplatePage';
import buttons from '@/utils/i18n/buttons';
import input from '@/utils/i18n/input';
import pos from '@/utils/i18n/pos';
import marketplace from '@/utils/i18n/marketplace';
import pricingSettings from '@/utils/i18n/pricingSettings';
import profile from '@/utils/i18n/profile';
import productions from '@/utils/i18n/productions';
import users from '@/utils/i18n/users';
import marketComparables from './marketComparables';

const en = {
  appName: 'PricerQX',
  buttons,
  notAvailable: 'N/A',
  optionsNotAvailable: 'Options not available',
  noAmount: '--.--',
  notFound: 'Not found',
  notFoundProduction: 'Production not found',
  selectProduction: 'Please select a production',
  loading: 'Loading...',
  loadingData: 'One moment please while getting new data...',
  savingData: 'One moment please while saving pricing group...',
  notAssigned: 'Not As',
  copyOff: 'Copy off %{source}',
  dateRange: {
    today: 'Today',
    tomorrow: 'Tomorrow',
    next7Days: 'Next 7 days',
    next15Days: 'Next 15 days',
    next30Days: 'Next 30 days',
    next90Days: 'Next 90 days',
    startDate: 'From',
    endDate: 'To',
    customDate: 'Custom Date',
    startDateToolTipMessage: 'Event date from',
    endDateToolTipMessage: 'Event date to',
    error: 'Invalid date range',
  },
  loadingMore: 'Loading more...',
  nothingToLoad: 'No more data to available',
  loaded: '%{fetched} of %{totalFiltered}',
  input,
  pos,
  marketplace,
  pricingSettings,
  marketComparables,
  marketplaceComparables: {
    info: {
      message:
        'Set the organization-wide market comparables defaults that helps all organization members quickly work on pricing. Any changes to pricing defaults will be applied to new pricing groups created afterwards. To reset back to the system defaults, select “Restore System Defaults”',
      button: 'restore system defaults',
    },
    activeMarket: {
      title: 'Active Market',
      venueSelection: {
        title: 'Venue map selection defaults',
        message:
          'The system default is Section. When Zone, the interactive venue chart would pick all sections in the zone.',
      },
      showMyListings: {
        title: 'Show my listings',
        message:
          'The system default is to display. When hidden, my listings will not be displayed in active market listings',
      },
      showOutliers: {
        title: 'Show outliers',
        message: 'System default is to display. When hidden, outliers will not be displayed in active market listing',
      },
      columns: {
        section: 'Listing Sect',
        mapSection: 'Map Sect',
        row: 'Row',
        quantity: 'Qty',
        stock: 'Delivery method',
        notes: 'Notes',
        attributes: 'Attributes',
        listPrice: 'Price (USD)',
      },
    },
  },
  noPos: {
    title: 'No data source for events overview',
    messageAdmin: 'Connect with POS to uncover insights to your pricing strategy',
    message: 'Please contact your Organization Admin for assistance',
    button: 'Connect POS',
    drive: {
      title: 'No data source to view.',
      message: 'Please contact your system administrator',
    },
  },
  pageNotFound: {
    code: '404',
    title: 'Page Not Found ⚠️',
    description: 'Sorry for the inconvenience but the page does not exist anymore',
  },
  unknownError: {
    title: 'Unknown error occurred ⚠️',
  },
  authorizationError: {
    code: '401',
    title: 'You are not authorized! 🔐',
    description: "You don't have permission to access this page. Go Home!",
  },
  serverError: {
    code: '500',
    title: 'Internal Server Error 🔐',
    description: 'Oops something went wrong',
  },
  launchingSoon: {
    title: 'We are launching soon 🚀',
    description: "Our website is opening soon. Please register to get notified when it's ready!",
  },
  underMaintenance: {
    title: 'Under Maintenance! ⚠️',
    description: "Sorry for the inconvenience but we're performing some maintenance at the moment",
  },
  auth: {
    signIn: {
      haveAnAccount: 'Already have a PricerQX account?',
    },
    buttons: {
      signIn: 'Sign in',
      signInInstead: 'Sign in instead',
    },
    inProgress: 'Authentication in progress...',
  },
  anErrorHasOccurred: 'An error has occurred',
  unknownErrorHasOccurred: 'Unknown error occurred',
  organization: {
    navTitle: 'Company Info',
    notifications: {
      applying: {
        title: 'Saving',
        message: 'Applying organization information changes.',
      },
      success: {
        title: 'Success',
        message: 'Successfully saved organization information changes.',
      },
      error: {
        title: 'Oops! Something went wrong!',
        message: 'Failed to save organization information changes.',
      },
    },
  },
  profile,
  notifications: {
    title: 'Notifications',
    markAll: 'mark all as read',
    viewAll: 'View all notifications',
    viewLess: 'View less',
    markAllAsRead: 'mark all as read',
    noNotifications: 'You have no new notifications',
  },
  orgRequest: {
    title: 'Your pricing journey starts here.',
    submitButton: 'Request Account',
    success: {
      title: 'You have successfully submitted your request!',
      message:
        "We appreciate your interest in PricerQX and will contact you via the email/phone you provided to complete your company's account setup.",
    },
    error: {
      title: 'There was an error submitting your request, please try again later.',
    },
    backToHome: 'Back to Home',
    requesterInfo: 'Your Information',
    companyInfo: 'Company Information',
  },
  sideBar: {
    apps: 'Pricing',
    systemSettings: 'Systems & Settings',
    relatedApps: 'Quick Links',
  },
  settings: {
    tabs: {
      company: 'Company Info',
      tooltipCompany: 'Company Information',
      billing: 'Billing',
      tooltipBilling: 'Billing and invoices',
      notifications: 'Notifications',
      tooltipNotifications: 'Billing',
      pos: 'POS',
      tooltipPos: 'Point of Sale configuration',
      marketplace: 'Marketplace',
      tooltipMarketplace: 'Marketplace configuration',
      users: 'Manage Members',
      tooltipUsers: 'Manage Members',
      tooltipUserEdit: 'Edit member detail',
      totalTicketGroups: 'Total of {{totalUnitsCounted}} ticket groups were used for pricing during this period',
      ticketGroups: 'Ticket Groups',
      dateInGmt: 'Date (GMT)',
      currentUsageDetails: 'Current Usage details',
      viewPlanAndInvoice: 'VIEW PLAN AND INVOICE',
      tooltipActions: 'More actions',
    },
    companyInfo: 'Company Info',
    companyAddress: 'Company Address',
    companyCountry: 'Company Country',
    state: 'State',
  },
  OrganizationDefaults: {
    tabs: {
      marketComparables: 'Market Comparables',
      pricing: 'Pricing',
    },
  },
  subscription: {
    title: 'Subscription Plan',
    subtitle: 'All plans include PricerQX advanced tools and features. Chose the best plan to fit your needs.',
    button: 'Upgrade',
    buttonExpire: 'Trial expires in %{days} days',
    frequency: {
      monthly: 'Monthly',
      annually: 'Annually',
      suffixMonthly: '/month',
      suffixAnnually: '/year',
    },
    popular: 'Popular',
    tier: {
      basic: {
        title: 'Basic',
        description: 'A simple start for everyone',
        features: [
          '100 responses a month',
          'Unlimited forms and surveys',
          'Unlimited fields',
          'Basic form creation tools',
          'Up to 2 subdomains',
        ],
      },
      standard: {
        title: 'Standard',
        description: 'For small to medium businesses',
        features: [
          'Unlimited responses',
          'Unlimited forms and surveys',
          'Instagram profile page',
          'Google Docs integration',
          'Custom “Thank you” page',
        ],
      },
      enterprise: {
        title: 'Enterprise',
        description: 'Solution for big organizations',
        features: [
          'PayPal payments',
          'Logic Jumps',
          'File upload with 5GB storage',
          'Custom domain support',
          'Stripe integration',
        ],
      },
    },
  },
  pricingTemplatesListPage: {
    title: 'Pricing Templates',
    searchPlaceholder: 'Event name, performer',
    applyTemplateTooltip: 'Apply pricing template',
    moreActions: 'More actions',
    menu: {
      deleteTemplate: 'Delete pricing template',
    },
    notifications: {
      deleteModal: {
        title: 'Delete pricing template?',
        statement: 'This pricing template is about to be deleted!',
        description: 'Proceeding will delete the template, it will not affect the pricing groups connected to it.',
        confirmCTA: 'Proceed',
      },
      delete: {
        successTitle: 'Success',
        successMessage: 'Pricing template has been deleted successfully.',
      },
    },
    columns: {
      name: 'Template Name',
      venue: 'Venue',
      location: 'Location',
      performer: 'Primary Performer',
      created: 'Created',
      time: 'Time',
      updated: 'Updated',
      lastTimeUsed: 'Last Time Used',
      pricingGroups: 'PG',
      pricingCriteria: 'Pricing Criteria',
      marketComparables: 'Market Comparables',
      updatedBy: 'Updated By',
      action: 'Action',
    },
  },
  pricingApplyTemplatePage,
  productions,
  inventory,
  pricingGroup: {
    preview: 'Preview',
    edit: 'Edit',
    ticketGroup: 'Ticket group',
    ticketGroups: 'Ticket groups',
    buttons: {
      delete: 'Delete',
      addTo: 'Add to',
    },
    feathering: 'Feathering',
    featheringWithValue: 'Feathering +%{value}',
    offsetWithValue: 'Offset',
    groupOffsetWithValue: 'Group',
    offsetMatch: 'Match',
    empty: {
      title: 'Pricing group has no ticket groups',
      message: 'You can add ticket groups, Cancel  to start over or Delete this pricing group.',
    },
    overlappingTicketGroupError: {
      message: 'Ticket group is assigned to another pricing group.',
    },
  },
  pricing: {
    noCriteria: 'No criteria',
    createNew: 'Create new',
    edit: 'Edit',
    title: 'Pricing Mode',
    savingTicketGroup: 'Saving changes',
    autoPricing: 'Auto pricing',
    simulation: 'Simulation',
    noComps: 'No Comps',
    untitled: 'Pricing Group Name',
    panels: {
      criteriaTitle: 'Criteria setting',
      groupTitle: 'Group setting',
      advancedTitle: 'Advanced setting',
      shareTitle: 'Share ticket groups',
      saveTitle: 'Save as',
      pushOption: 'Push option',
      customName: 'Save As',
    },
    labels: {
      adjustment: 'Offset',
      groupAdjustment: 'Group Offset',
      method: 'Method',
      amount: 'Amount',
      floorPrice: 'Floor',
      ceilingPrice: 'Ceiling',
      nameToggle: 'Name the pricing group',
      saveAsPricingTemplate: 'Save as pricing template',
      criteriaName: 'Pricing group name',
      ticketGroups: '%{count} ticket group',
    },
    hint: {
      criteriaName: 'It will be blank until it is named.',
      criteriaNameMultipleTicketGroups: 'Pricing group must be named.',
      selectTicketGroup: 'Select a ticket group to start pricing',
      disabled: 'Pricing disabled',
    },
    errors: {
      offset: 'Offset is required',
      groupOffset: 'Group offset is required',
      floorCeiling: 'Ceiling must be greater than floor',
      floorBellowCost: 'Floor below costs',
      floorRequired: 'Floor is required',
      ceilingRequired: 'Ceiling is required',
      ceilingRequiredByNoComp: 'Ceiling is required for the advanced option selected',
      name: 'Unsupported character used. Can only contain characters, numbers, and these special characters ! # $ % & * ( ) @ - + { } [ ] < > ? / \\',
      production: {
        title: 'No production selected',
        message: 'Please select a production to start pricing.',
      },
      getPricingGroup: {
        title: "Can't get  pricing group",
        message: 'Getting pricing group to update failed. Please try again later.',
      },
    },
    pushOptions: {
      autoPrice: 'Auto pricing',
      simulation: 'Simulation only',
      oneTime: 'One time',
    },
    shareOptions: {
      title: 'Share ticket groups',
      all: 'All',
      even: 'Even',
      odd: 'Odd',
      anchorLabel: 'Share anchor ticket group',
      custom: 'Custom',
    },
    advancedOptions: {
      randomFloor: 'Randomize the pricing when SCP hits floor',
      randomCeiling: 'Randomize the pricing when SCP hits ceiling',
      enableNoComps: 'Enable no comps option',
      options: {
        freeze: 'Freeze pricing until new comps',
        fix: 'Fix all prices at ceiling',
        randomize: 'Randomize the pricing off ceiling',
      },
      useFloorPrice: 'Use Floor Price',
      randomizeFloor: 'Randomize when hits floor',
      useCeilingPrice: 'Use Ceiling Price',
      randomizeCeiling: 'Randomize when hits ceiling',
      freezePricing: 'Freeze pricing until new comps',
      useFloorForAnchor: 'Use Floor Price as anchor',
      useCeilingForAnchor: 'Use Ceiling Price as anchor',
      fixAllPricesCeiling: 'Fix all prices at ceiling price',
      randomizeOffCeiling: 'Randomize pricing off ceiling price',
    },
    notification: {
      pricingCriteria: {
        applying: {
          title: 'Applying',
          message: 'Applying pricing criteria...',
        },
        success: {
          title: 'Success',
          message: 'Pricing criteria applied successfully.',
          pricingGroupAndTemplateSaved: 'Successfully saved the pricing group and pricing template.',
          pricingTemplateSaved: 'Successfully saved as a pricing template.',
          applyTemplate: 'Apply template',
        },
        error: {
          title: 'Oops! Something went wrong!',
          message: 'Pricing criteria failed to apply.',
        },
      },

      onTimePush: {
        applying: {
          title: 'Applying',
          message: 'Updating list price for %{productionName} on %{productionDate}.',
        },
        success: {
          title: 'Success',
          message: '%{productionName} on %{productionDate} list price has been updated.',
        },
        error: {
          title: 'Oops! Something went wrong!',
          message: 'List price for %{productionName} on %{productionDate} failed.',
        },
      },
    },
    tooltip: {
      group: {
        title: 'Group',
        description: '- Set the group setting',
      },
      suggestedPrice: {
        title: 'Suggested criteria price',
        description: '- Get the suggested criteria price',
      },
      autoPrice: {
        title: 'Auto Price',
        description:
          '- We will update POS list price, we will save the criteria with the name given, and monitor to the market for suggesting the price and updating the POS list price',
      },
      simulation: {
        title: 'Simulation Only',
        description:
          '- We will not update POS list price but will save the criteria with the name given, and monitor to the market for suggesting the price',
      },
      onePush: {
        title: 'One-time Push',
        description: '- We update the POS list price and do not save the criteria entered or monitor the ticket groups',
      },
      nameToggle: {
        optional: 'Activate to name the pricing group, disable to save with no name',
        disabled:
          'Current configuration not allowing to toggle name input activity. One time push will not allow a name to be entered and for any 2 and more ticket groups will require a name to be entered.',
      },
      advancedOptions: {
        floorTitle: 'Floor option when Suggested price hits floor',
        floorContent:
          'We will price using floor price with variant that will apply to each ticket group while keeping the prices above the floor.',
        ceilingTitle: 'Ceiling option when Suggested price hits ceiling',
        ceilingContent:
          'We will price using ceiling price with variant that will be applied to each ticket group while keeping the prices below the ceiling.',
        noCompsTitle: 'No comps option',
        noCompsContent:
          'By default, we will freeze prices for all ticket groups until new comps are introduced. Randomize the pricing off ceiling will be using ceiling price with variant while keeping the prices below the ceiling.',
      },
    },
  },
  market,
  users,
  search: {
    noResults: 'No results found',
    zeroResults: '0 results for "%{searchTerm}"',
    tipTitle: 'Tips for improving the search results:',
    recentSearches: 'Recent Searches',
    checkDateRange: 'Check your date range',
    noRecentSearches: 'No recent searches',
    buttonDateRange: 'Date Range',
    checkSpelling: 'Check your spelling',
    tryDifferentKeywords: 'Try different keywords',
    tryBelowRecentSearches: 'Try below recent searches',
    startOver: 'Start over by clearing search results',
  },
  roles: {
    title: 'Role',
    owner: 'Owner',
    employee: 'Employee',
    admin: 'Admin',
    viewer: 'Viewer',
    pricer: 'Pricer',
  },
  allRoles: 'All',
  signOut: {
    title: 'Signed out',
    button: 'Sign back in',
    detail: 'You have been signed out due to inactivity.',
  },
  invitation: {
    title: 'Invitation validation',
    validation: 'Validating invitation, please wait...',
    error: "Something went wrong, we couldn't validate your invitation",
  },
  chips: {
    tbd: 'TBD',
    tba: 'TBA',
    scheduled: 'Scheduled',
  },
  seatTypes: {
    full: {
      CONSECUTIVE: 'Consecutive',
      ODD_EVEN: 'Odd/Even',
      PIGGYBACK: 'Piggyback',
      GENERAL_ADMISSION: 'General Admission',
    },
    abbreviations: {
      Consecutive: 'C',
      OddEven: 'OE',
      Piggyback: 'PG',
      GeneralAdmission: 'GA',
    },
  },
  map: {
    buttons: {
      reset: 'Reset',
      zoomIn: '+',
      zoomOut: '-',
      noTickets: 'No tickets',
      show: 'Show',
      hide: 'Hide',
      zones: 'Zones',
    },
    requestMapping: {
      title: 'request mapping',
      productionNoMapped: 'Production is not mapped.',
      productionMappingRequestLoading: 'Loading',
      notMappedDescription: 'Submit a priority mapping request to PRQX support team.',
      submittedTitle: 'Mapping request submitted.',
      submittedDescription: 'If event remains unmapped after one business day, contact us at support@pricerqx.com.',
      alreadyMappedTitle: 'Request already mapped.',
      alreadyMappedDescription: 'An error has occurred, please reload the page.',
    },
    requestMappingSlideOver: {
      title: 'Request mapping',
      mappingFrom: 'Mapping from',
      mappingTo: 'Mapping to',
      search: 'Search source event',
      searchPlaceholder: 'Search Event name, Performer, Venue, Source ID',
      submitRequest: 'Submit request',
      date: 'Date',
      noResultsHint: 'Adjust search criteria and try again',
      notification: {
        success: {
          title: 'Successfully submitted mapping request for %{eventName}',
        },
        error: {
          title: 'Unable to submit mapping request.',
          message: 'Try to submit mapping request again or contact our support team at Support@PricerQX.com',
        },
      },
    },
  },
  tablePreferences: {
    title: 'Customize columns - %{tableName}',
    displayedColumnsTitle: 'Displayed columns',
    hiddenColumnsTitle: 'Hidden columns',
    columnNames: 'Column names',
    tableSettingsTooltip: 'Customize columns',
    events: 'Events',
    inventory: 'Inventory',
    activeMarketPlace: 'Market',
    pinned: 'Unpin column',
    unpinned: 'Pin column',
  },
  dialogs: {
    pricingProgressWarning: {
      title: 'There are changes that will be lost!',
      statement: 'Proceed and discard all changes?',
      description: 'Proceeding will delete all changes made and will not be recoverable',
      cancelCTA: 'Cancel',
      noCancel: 'No, cancel',
      okCTA: 'Proceed',
    },
    deletePricingGroup: {
      title: 'Delete pricing group?',
      statement: 'This pricing group is about to be deleted.',
      description: 'All of the ticket groups will be unlinked. This action cannot be undone.',
      cancelCTA: 'Cancel',
      okCTA: 'Delete',
    },
  },
  banners: {
    autoPriceDisabled: {
      title: 'Auto Price is suspended.',
      description:
        'All your pricing groups will run in “SIMULATE” mode only. Please contact support at support@pricerqx.com.',
    },
    pricingDisabled: {
      title: 'All pricing is disabled.',
      description:
        'You won’t be able to edit any of your pricing groups or create new ones. Please contact support at support@pricerqx.com.',
    },
    unknownError: {
      title: 'Pricing service unknown error',
      description: 'Please contact support at support@pricerqx.com.',
    },
  },
  navigation: {
    navigationSettings: 'Navigation settings',
    floatNavigation: 'Float navigation',
    autoMinimize: 'Auto minimize',
    version: 'Version',
    tooltips: {
      pos: 'Point of Sale configuration',
      notification: 'View notification',
      profile: 'Profile overview',
    },
  },
  requestError,
  resetPassword: {
    successTitle: 'Success',
    enterNewPassword: 'Please enter your new password',
    successMessage: 'Member password has been reset successfully.',
    buttons: {
      resetPassword: 'Reset Password',
    },
  },
  forgotPassword: {
    title: 'Forgot password',
    instructions: 'Enter your email and we will send you instructions to reset your password.',
    successTitle: 'Success',
    sendLink: 'Send me the link',
    successMessage:
      'Thank you. Please check your email inbox and junk mail. If you do not receive an email with a link to reset your password, please contact your organization administrator.',
    buttons: {
      resetPassword: 'Reset Password',
    },
    inputPlaceHolder: 'Enter your email',
  },
  dropDownInput: {
    more: 'More',
  },
};

export default en;
