import type {
  HitCeilingPricingMethod,
  HitFloorPricingMethod,
  HitNoCompPricingMethod,
  OrganizationPricingDefaultDto,
  PricingGroupRequest,
} from '@/api/pricer/pricerApiSchemas';
import { adjustments, customSharingUnits, groupAdjustments, units } from '@/context/pricing-mode/constants';
import { formatCurrencyInput } from '@/utils/helpers';
import type { CustomSharingMethod } from '@/utils/constants';
import { groupShareOptions } from '@/utils/constants';
import type { InventoryPricingState } from '@/context/pricing-mode/reducer-constants';
import { INITIAL_STATE } from '@/context/pricing-mode/reducer-constants';
import { FieldType, getValidatedInput, getValidatedShareInput } from '@/context/pricing-mode/reducer-constants';

export const loadFromPricingGroup = (pricingGroup: PricingGroupRequest): InventoryPricingState => {
  const { ruleSet, floorPrice, ceilingPrice, pricingGroupRequestType } = pricingGroup;

  if (ruleSet) {
    const {
      amount,
      increment,
      incrementMethod,
      offsetAmount,
      offsetIncrement,
      offsetIncrementMethod,
      floorPricingMethod,
      ceilingPricingMethod,
      noCompPricingMethod,
      shareAnchor,
      shareOptionsType,
      enableShareOptions,
      customSharingMethod,
      customSharingAmount,
    } = ruleSet;

    return {
      ...INITIAL_STATE,
      floor: getValidatedInput(FieldType.FLOOR, formatCurrencyInput(floorPrice?.toString() ?? '')),
      ceiling: getValidatedInput(FieldType.CEILING, formatCurrencyInput(ceilingPrice?.toString() ?? '')),
      adjustmentAmount: getValidatedInput(FieldType.ADJUSTMENT_AMOUNT, formatCurrencyInput(amount?.toString() ?? '')),
      adjustment: adjustments.find((item) => item.key.toLowerCase() === increment?.toLowerCase()) ?? adjustments[1],
      unit: units.find((item) => item.key === incrementMethod?.toString()) ?? units[0],
      pushOption:
        INITIAL_STATE.allPushOptions.find(
          (option) => option.key?.toLowerCase() === pricingGroupRequestType?.toLowerCase(),
        ) ?? INITIAL_STATE.allPushOptions[0],
      name: getValidatedInput(FieldType.NAME, pricingGroup.name || ''),
      groupAdjustment:
        groupAdjustments.find((item) => item.key.toLowerCase() === offsetIncrement?.toLowerCase()) ??
        groupAdjustments[1],
      groupAdjustmentAmount: getValidatedInput(
        FieldType.GROUP_ADJUSTMENT_AMOUNT,
        formatCurrencyInput((offsetAmount ?? 0).toString()),
      ),
      groupUnit: units.find((item) => item.key === offsetIncrementMethod) ?? units[0],
      groupShareOption:
        groupShareOptions.find((item) => item.key.toLowerCase() === shareOptionsType?.toLowerCase()) ??
        groupShareOptions[0],
      isSharingActive: !!enableShareOptions,
      shareTicketGroupsUnit:
        customSharingUnits.find((item) => item.key === customSharingMethod) ?? customSharingUnits[0],
      shareTicketGroupsAmount: getValidatedShareInput(
        (customSharingAmount ?? 0).toString(),
        (customSharingUnits.find((item) => item.key === customSharingMethod) ?? customSharingUnits[0])
          .key as CustomSharingMethod,
        false,
      ),
      floorPricingMethod: floorPricingMethod as HitFloorPricingMethod,
      ceilingPricingMethod: ceilingPricingMethod as HitCeilingPricingMethod,
      noCompPricingMethod: noCompPricingMethod as HitNoCompPricingMethod,
      isAnchorShared: !!shareAnchor,
    };
  } else {
    return INITIAL_STATE;
  }
};

export const loadFromOrgDefaults = (orgDefaults: OrganizationPricingDefaultDto): typeof INITIAL_STATE => {
  const { pricingDefaults, isCeilingRequired } = orgDefaults;

  if (pricingDefaults) {
    const {
      ceiling: orgCeiling,
      floor: orgFloor,
      increment: orgIncrement,
      incrementType: orgIncrementType,
      incrementDirection: orgIncrementDirection,
      groupOffset: orgGroupOffset,
      groupOffsetType: orgGroupOffsetType,
      groupOffsetDirection: orgGroupOffsetDirection,
      isShareOptionEnabled: orgIsShareOptionEnabled,
      shareOptionsType: orgShareOptionsType,
      customSharingMethod: orgCustomSharingMethod,
      customSharingAmount: orgCustomSharingAmount,
      shareAnchor: orgShareAnchor,
      pricingGroupRequestType: orgPricingGroupRequestType,
      floorPricingMethod: orgFloorPricingMethod,
      ceilingPricingMethod: orgCeilingPricingMethod,
      noCompPricingMethod: orgNoCompPricingMethod,
    } = pricingDefaults;

    return {
      ...INITIAL_STATE,
      ...getValidatedInput(FieldType.FLOOR, formatCurrencyInput(orgFloor?.toString() ?? '')),
      ...getValidatedInput(FieldType.CEILING, formatCurrencyInput(orgCeiling?.toString() ?? '')),
      ...getValidatedInput(FieldType.ADJUSTMENT_AMOUNT, formatCurrencyInput(orgIncrement?.toString() ?? '')),
      adjustment:
        adjustments.find((item) => item.key.toLowerCase() === orgIncrementDirection?.toLowerCase()) ?? adjustments[1],
      unit: units.find((item) => item.key === orgIncrementType?.toString()) ?? units[0],
      pushOption:
        INITIAL_STATE.allPushOptions.find(
          (option) => option.key?.toLowerCase() === orgPricingGroupRequestType?.toLowerCase(),
        ) ?? INITIAL_STATE.allPushOptions[0],
      groupAdjustment:
        groupAdjustments.find((item) => item.key.toLowerCase() === orgGroupOffsetDirection?.toLowerCase()) ??
        groupAdjustments[1],
      ...getValidatedInput(FieldType.GROUP_ADJUSTMENT_AMOUNT, formatCurrencyInput((orgGroupOffset ?? 0).toString())),
      groupUnit: units.find((item) => item.key === orgGroupOffsetType?.toString()) ?? units[0],
      groupShareOption:
        groupShareOptions.find((item) => item.key.toLowerCase() === orgShareOptionsType?.toLowerCase()) ??
        groupShareOptions[0],
      isSharingActive: !!orgIsShareOptionEnabled,
      shareTicketGroupsUnit:
        customSharingUnits.find((item) => item.key === orgCustomSharingMethod) ?? customSharingUnits[0],
      shareTicketGroupsAmount: getValidatedShareInput(
        (orgCustomSharingAmount ?? 0).toString(),
        (customSharingUnits.find((item) => item.key === orgCustomSharingMethod) ?? customSharingUnits[0])
          .key as CustomSharingMethod,
        false,
      ),
      floorPricingMethod: orgFloorPricingMethod as HitFloorPricingMethod,
      ceilingPricingMethod: orgCeilingPricingMethod as HitCeilingPricingMethod,
      noCompPricingMethod: orgNoCompPricingMethod as HitNoCompPricingMethod,
      isAnchorShared: !!orgShareAnchor,
      isCeilingRequired: !!isCeilingRequired,
    };
  } else {
    return INITIAL_STATE;
  }
};

export const loadFromMetadata = (
  state: typeof INITIAL_STATE,
  pricingGroup: PricingGroupRequest,
): InventoryPricingState => {
  const { ruleSet, floorPrice, ceilingPrice, pricingGroupRequestType, name } = pricingGroup;

  const {
    amount,
    increment,
    incrementMethod,
    offsetAmount,
    offsetIncrement,
    offsetIncrementMethod,
    floorPricingMethod,
    ceilingPricingMethod,
    noCompPricingMethod,
    shareAnchor,
    shareOptionsType,
    enableShareOptions,
    customSharingMethod,
    customSharingAmount,
  } = ruleSet ?? {};

  return {
    ...state,
    floor: getValidatedInput(FieldType.FLOOR, formatCurrencyInput(floorPrice?.toString() ?? '')),
    ceiling: getValidatedInput(FieldType.CEILING, formatCurrencyInput(ceilingPrice?.toString() ?? '')),
    adjustmentAmount: getValidatedInput(FieldType.ADJUSTMENT_AMOUNT, formatCurrencyInput(amount?.toString() ?? '')),
    adjustment: adjustments.find((item) => item.key.toLowerCase() === increment?.toLowerCase()) ?? adjustments[1],
    unit: units.find((item) => item.key === incrementMethod?.toString()) ?? units[0],
    pushOption:
      INITIAL_STATE.allPushOptions.find(
        (option) => option.key?.toLowerCase() === pricingGroupRequestType?.toLowerCase(),
      ) ?? INITIAL_STATE.allPushOptions[0],
    name: getValidatedInput(FieldType.NAME, name || ''),
    groupAdjustment:
      groupAdjustments.find((item) => item.key.toLowerCase() === offsetIncrement?.toLowerCase()) ?? groupAdjustments[1],
    groupAdjustmentAmount: getValidatedInput(
      FieldType.GROUP_ADJUSTMENT_AMOUNT,
      formatCurrencyInput((offsetAmount ?? 0).toString()),
    ),
    groupUnit: units.find((item) => item.key === offsetIncrementMethod) ?? units[0],
    groupShareOption:
      groupShareOptions.find((item) => item.key.toLowerCase() === shareOptionsType?.toLowerCase()) ??
      groupShareOptions[0],
    isSharingActive: !!enableShareOptions,
    shareTicketGroupsUnit: customSharingUnits.find((item) => item.key === customSharingMethod) ?? customSharingUnits[0],
    shareTicketGroupsAmount: getValidatedShareInput(
      (customSharingAmount ?? 0).toString(),
      (customSharingUnits.find((item) => item.key === customSharingMethod) ?? customSharingUnits[0])
        .key as CustomSharingMethod,
      false,
    ),
    floorPricingMethod: floorPricingMethod as HitFloorPricingMethod,
    ceilingPricingMethod: ceilingPricingMethod as HitCeilingPricingMethod,
    noCompPricingMethod: noCompPricingMethod as HitNoCompPricingMethod,
    isAnchorShared: !!shareAnchor,
  };
};
