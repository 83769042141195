import type { FilterFn } from '@tanstack/react-table';
import type { ProductionSection } from '../components/SeatMap/type';
import type {
  IFilterNamed,
  IFilterSimple,
  IFilters,
  IInventoryFilters,
  IMarketFilters,
  IProductionsFilters,
  IRadioOption,
} from '@/interfaces/CommonInterfaces';
import { EFilterType } from '@/interfaces/CommonInterfaces';
import { ThreeStateOption, UserStatusFilter } from './constants';
import i18n from './i18n';

export function getAllFilterStatuses() {
  return Object.keys(UserStatusFilter).map((status) => ({
    value: status,
    name: i18n.t(`users.status.${status.toLowerCase()}`),
  }));
}

//ToDo -remove?
// returns IFilterSimple object split into two objects {trueFilters: {}, falseFilters: {}}
export function getSortedFilters(obj: IFilterSimple | IFilterNamed): {
  trueValues: IFilterSimple | IFilterNamed;
  falseValues: IFilterSimple | IFilterNamed;
} {
  const trueValues: IFilterSimple | IFilterNamed = {};
  const falseValues: IFilterSimple | IFilterNamed = {};

  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      const option: { isChecked: boolean; displayName: string } = obj[key] as any;
      option.isChecked ? (trueValues[key] = option) : (falseValues[key] = option);
    } else {
      obj[key] ? (trueValues[key] = true) : (falseValues[key] = false);
    }
  }

  return { trueValues, falseValues };
}

//ToDo -remove?
export function sortFilters(filters: IFilters): IFilters {
  const sortedFilter: Record<string, any> = {};

  for (const key in filters) {
    if (filters.hasOwnProperty(key) && typeof filters[key] === 'object') {
      const subObj = filters[key];
      sortedFilter[key] = {};

      const trueKeys = [];
      const falseKeys = [];

      for (const subKey in subObj) {
        if (subObj.hasOwnProperty(subKey)) {
          if (subObj[subKey] === true) {
            trueKeys.push(subKey);
          } else {
            falseKeys.push(subKey);
          }
        }
      }

      if (trueKeys.length > 0) {
        trueKeys.sort();
        falseKeys.sort();
        const reorderedKeys = [...trueKeys, ...falseKeys];

        for (const subKey of reorderedKeys) {
          sortedFilter[key][subKey] = subObj[subKey];
        }
      } else {
        const allKeys = Object.keys(subObj).sort();

        for (const subKey of allKeys) {
          sortedFilter[key][subKey] = subObj[subKey];
        }
      }
    }
  }
  return sortedFilter;
}

export function getFilterCount<T extends IProductionsFilters | IInventoryFilters | IMarketFilters>(
  filters: T,
  filterOptions: T,
): number {
  return Object.keys(filters).reduce((acc: number, item) => {
    const filter = filters[item as keyof T];

    switch ((filter as any).type) {
      case EFilterType.OPTIONS:
        const selected = (filter as any).data.options.find((item: any) => item.selected === true);
        if (selected) return acc + 1;
        break;
      case EFilterType.RADIO:
        const initFilter = filterOptions[item as keyof T];
        const initSelectedValue = ((initFilter as any).data as IRadioOption)?.selected;
        const selectedValue = (filter as any).data?.selected;
        if (initSelectedValue && selectedValue && initSelectedValue.key !== selectedValue.key) return acc + 1;
        break;
      case EFilterType.YESNO:
        const initFilterYesNo = filterOptions[item as keyof T];
        const initSelectedValueYesNo = ((initFilterYesNo as any).data as IRadioOption)?.selected;
        const selectedValueYesNo = (filter as any).data?.selected;
        if (initSelectedValueYesNo && selectedValueYesNo && initSelectedValueYesNo.key !== selectedValueYesNo.key)
          return acc + 1;
        break;
      case EFilterType.INPUT:
        const initFilterInput = filterOptions[item as keyof T];
        const initSelectedValueInput = ((initFilterInput as any).data as { label: string; option: string | number })
          ?.option;
        const selectedValueInput = (filter as any).data?.option;
        if (selectedValueInput && initSelectedValueInput !== selectedValueInput) return acc + 1;
        break;
      case EFilterType.TOGGLE:
        const initFilterToggle = filterOptions[item as keyof T];
        const initSelectedValueToggle = (initFilterToggle as any).data;
        const selectedValueToggle = (filter as any).data;
        if (initSelectedValueToggle !== selectedValueToggle) return acc + 1;
        break;
      default:
        throw new Error('Unknown filter type for counter: ' + (filter as any).type + ' !');
    }
    return acc;
  }, 0);
}

//ToDo - remove this
export function getTrueValuesFilteredFromObject(obj: IFilters): Record<string, string[]> {
  const trueValues: Record<string, string[]> = {};

  for (const key in obj) {
    const group = obj[key];
    trueValues[key] = [];
    for (const option in group) {
      if (typeof group[option] === 'object') {
        const namedOption = group[option] as { isChecked: boolean; displayName: string };
        if (namedOption.isChecked) {
          trueValues[key].push(namedOption.displayName);
        }
      } else {
        const boolOption = group[option] as boolean;
        if (boolOption) {
          trueValues[key].push(option);
        }
      }
    }
  }

  return trueValues;
}

export function combineSections(mapSections: ProductionSection[], productionSections: string[]): string[] {
  const combinedSectionNamesMap: { [key: string]: string } = {};

  mapSections?.forEach((section) => {
    const lowercaseName = section.name.toLowerCase();
    combinedSectionNamesMap[lowercaseName] = section.name;
  });

  productionSections?.forEach((sectionName) => {
    const lowercaseName = sectionName.toLowerCase();
    combinedSectionNamesMap[lowercaseName] = sectionName;
  });

  const combinedSectionNames = Object.values(combinedSectionNamesMap);

  const sortedCombinedSectionNames = combinedSectionNames.sort();

  return sortedCombinedSectionNames;
}

export const equalsStringArray: FilterFn<any> = (row, columnId: string, filterValue: string[]) => {
  return filterValue.some((val) =>
    row.getValue<string[]>(columnId).some((colValue) => colValue?.toString().toLowerCase() === val.toLowerCase()),
  );
};

export const threeStateFilterFn: FilterFn<any> = (
  row,
  columnId: string,
  filterValue: { key: string; state: ThreeStateOption },
) => {
  const returnVal = row.getValue<string>(columnId).toLowerCase() === filterValue.key.toLowerCase();

  return filterValue.state === ThreeStateOption.ONLY
    ? returnVal
    : filterValue.state === ThreeStateOption.HIDE
      ? !returnVal
      : true;
};

export const arrIncludeSome: FilterFn<any> = (row, columnId: string, filterValue: string[]) => {
  return filterValue.some((val) => row.getValue<string>(columnId) === val);
};

export const filterOriginalRowValue: FilterFn<any> = (row, columnId: string, filterValue: string[]) => {
  return filterValue.some((val) => row.original[columnId] === val);
};
