import { useState } from 'react';
import type { IQueryError } from '@/interfaces/CommonInterfaces';
import { Button } from '../components/common';
import style from './SbLoginApp.module.css';
import clsx from 'clsx';
import sbLogo from '@/assets/images/sb_poweredby_prqx_m.svg';
import { getErrorMessage } from './notifications';

function SbLoginApp() {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<IQueryError>();

  const handleLogin = () => {
    setError(undefined);
    fetch(`${import.meta.env.REACT_APP_SKYBOX_SESSION_URL}`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        host: 'skybox.vividseats.com',
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({ email: username, password: password, rememberMe: false }),
    })
      .then((response) => {
        if (!response.ok) {
          setError({ message: 'Skybox Login Error' });
        } else {
          setError(undefined);
        }
        return response.json();
      })
      .then((response) => {
        if (!error) {
          processLoginPayload(response);
        } else {
          console.log(response);
        }
      })
      .catch((err) => {
        console.error(err);
        setError({ message: 'Skybox Login Error' });
      });
  };

  interface ISbResponsePayload {
    accountId: number;
    userId: number;
    token: string;
    refreshToken: string;
    expiresIn: number;
    agreedToTerms: boolean;
  }

  const processLoginPayload = (payload: ISbResponsePayload) => {
    const connectUrl = import.meta.env.REACT_APP_IDENTITY_CONNECT_URL;

    fetch(`${connectUrl}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        accountId: payload.accountId,
        userId: payload.userId,
        token: payload.token,
        refreshToken: payload.refreshToken,
        expiresIn: payload.expiresIn,
      }),
    })
      .then((response) => {
        setError(undefined);
        if (response.ok) {
          return response.json();
        } else {
          console.log(`${response.status} -  ${response.statusText}`);
          setError({ message: 'Drive Auth. Error' });
        }
      })
      .then((response) => {
        window.location.href = `${import.meta.env.REACT_APP_IDENTITY_REDIRECT_URL}?sbauid=${response.sbauid}`;
      })
      .catch((err) => {
        console.error(err);
        setError({ message: 'Drive Auth. Error' });
      });
  };

  return (
    <div className={style.container}>
      <div className={style.content}>
        <div className="flex justify-center">
          <img alt="SkyBoxDrive Logo" className="h-30" src={sbLogo} />
        </div>
        <h2 className={clsx('text-secondary my-4 text-center text-sm', style.colorTextSecondary)}>
          Please sign in to begin your pricing journey!
        </h2>
        <div className="flex w-full justify-center">
          <div className="w-full flex-col">
            <div className="flex-row text-center">
              <input
                className={clsx(style.colorTextSecondary, style.input, 'placeholder:text-slate-400')}
                placeholder="E-mail"
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="mt-4 flex-row text-center">
              <input
                className={clsx(style.colorTextSecondary, style.input, 'placeholder:text-slate-400')}
                placeholder="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <Button
              className={clsx(style.backgroundPrimaryMain, style.colorTextSecondary, style.button)}
              label="Login"
              onClick={handleLogin}
              variant="legacy"
            />
            {error && <ErrorMessage error={error} />}
          </div>
        </div>
      </div>
    </div>
  );
}

interface IProps {
  error: IQueryError | undefined;
}
function ErrorMessage({ error }: IProps) {
  return (
    <h2 className={clsx(errorStyles.errorTitle, style.colorErrorMain)}>
      {getErrorMessage(error ? error.message : '').message}
    </h2>
  );
}

const errorStyles = {
  errorTitle: 'my-8 text-md text-center',
};

export default SbLoginApp;
