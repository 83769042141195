import clsx from 'clsx';

interface IProps {
  className?: string;
  children: React.ReactNode;
}

function PageLayout({ className, children }: IProps) {
  return (
    <div className={clsx('flex h-full w-full flex-col [:where(&)]:bg-paper-card [:where(&)]:pb-2', className)}>
      {children}
    </div>
  );
}

export default PageLayout;
