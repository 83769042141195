import useTitle from '@/hooks/useTitle';
import i18n from '@/utils/i18n';
import { NavLink, useSearchParams } from 'react-router-dom';
import { Button } from '../components/common';
import { AppModeIsDrive } from '@/utils/constants';
import { useEffect } from 'react';
import sbLogo from '@/assets/images/sb_poweredby_prqx_m.svg';
import { KEY_MSALIDTOKEN, KEY_SBAUID } from '@/services/auth-service-ids';
import FSRegisterPageProperties from '@/services/fs-page-properties';
import useAppInsightsNoUserNoOrganization from '@/hooks/useAppInsightsNoUserNoOrganization';

function SignOut() {
  useAppInsightsNoUserNoOrganization();
  useTitle({ title: i18n.t('signOut.title') });

  useEffect(() => {
    FSRegisterPageProperties(i18n.t('signOut.title'));
  }, []);

  const [urlSearchParams] = useSearchParams();

  const state = urlSearchParams.get('state');
  useEffect(() => {
    // clearing all session tokens
    window.sessionStorage.clear();
    window.localStorage.removeItem(KEY_SBAUID);
    window.localStorage.removeItem(KEY_MSALIDTOKEN);
  }, []);

  let logo = '/logo.svg';
  if (AppModeIsDrive) {
    logo = sbLogo;
  }

  return (
    <div className="auth-background scrollbar">
      <header className="flex min-h-full flex-col  items-center justify-center gap-8">
        <img src={logo} className="mb-20 h-[6.4rem] px-12" alt="logo" />
        <div className="w-72 border-b-2 border-divider"></div>
        <h1 className="text-l txt-primary md:text-2xl">{i18n.t('signOut.detail')}</h1>
        {!AppModeIsDrive ? (
          <NavLink to={state || '/'}>
            <Button label={i18n.t('signOut.button').toUpperCase()} className="w-40" />
          </NavLink>
        ) : null}
        {AppModeIsDrive ? (
          <a href={import.meta.env.REACT_APP_REDIRECT_ON_LOGOUT}>
            <Button label={i18n.t('signOut.button').toUpperCase()} className="w-40" />
          </a>
        ) : null}
      </header>
    </div>
  );
}

export default SignOut;
