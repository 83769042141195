export default {
  notifications: {
    applying: {
      title: '',
      message: 'Saving organization pricing defaults.',
    },
    success: {
      title: '',
      message: 'Successfully saved organization pricing defaults.',
    },
    error: {
      title: '',
      message: 'Failed to save organization pricing defaults.',
    },
  },
  infoMessage:
    'Set the organization-wide market comparables defaults that helps all organization members quickly work on pricing. Any changes to pricing defaults will be applied to new pricing groups created afterwards. To reset back to the system defaults, select “Restore System Defaults”',
  labels: {
    restore: 'Restore System Defaults',
    inputToolTip: 'Enter numbers only',
  },
  comparableOptions: {
    title: 'Comparable options',
    splitsDefault: {
      title: 'Splits default',
      message:
        'System default is disabled. When enabled, the splits will be selected to based on the quantity of anchor ticket group.',
      toggleMessage: 'Continuously monitor and update',
    },
  },
};
