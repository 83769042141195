import { NavLink } from 'react-router-dom';
import { Button } from '../../components/common';
import i18n from '@/utils/i18n';
import { AppModeIsDrive } from '@/utils/constants';
import sbLogo from '@/assets/images/sb_poweredby_prqx_m.svg';
import FsTrackEvent, { EventNames } from '@/services/fs-track-event';
import { useEffect } from 'react';
import { appInsights } from '@/appinsights';

interface IProps {
  text?: string;
  techDetails?: string;
  onHome?: () => void;
}

function UnknownError({ text, onHome, techDetails }: IProps) {
  useEffect(() => {
    FsTrackEvent(EventNames.unknownError, { error: techDetails });
    appInsights.trackException({
      exception: new Error(techDetails),
    });

    console.error(techDetails);
  }, [techDetails]);

  let logo = '/logo.svg';
  if (AppModeIsDrive) {
    logo = sbLogo;
  }

  const textDefault = i18n.t('buttons.backHome').toUpperCase();

  return (
    <div className="auth-background scrollbar">
      <header className="error-container">
        <img src={logo} className="error-app-logo" alt="logo" />
        <p className="error-long-desc">{i18n.t('anErrorHasOccurred')}</p>
        {onHome ? (
          <NavLink to={'/'} onClick={onHome}>
            <Button label={text || textDefault} />
          </NavLink>
        ) : null}
      </header>
    </div>
  );
}

export default UnknownError;
