import { AuthApiFetcherOptions } from '@/api/AuthApiContext';
import type { GetProductionsQueryParams } from '@/api/pricer/pricerApiComponents';
import { fetchGetProductions, fetchGetProductionsInPage } from '@/api/pricer/pricerApiComponents';
import type { IsoDayOfWeek, ProductionRequestSortColumn } from '@/api/pricer/pricerApiSchemas';
import type {
  IOptionsSet,
  IProductionQuickFilters,
  IProductionsFilters,
  IRadioOption,
  ProductionsQuickFiltersKeys,
} from '@/interfaces/CommonInterfaces';
import { EFilterType } from '@/interfaces/CommonInterfaces';
import type { ThreeStateOption } from '@/utils/constants';
import { Role } from '@/utils/constants';
import { ProductionStatus, SERVER_DATE_FORMAT, ThreeState } from '@/utils/constants';
import { getBooleanValueFromThreeState } from '@/utils/helpers';
import i18n from '@/utils/i18n';
import { format } from 'date-fns';
import type { columnSortType } from './context-hooks';
import type { ClientUserAppProfileResponse } from '@/api/clientService/clientServiceApiSchemas';

export const productionFilterOptions: IProductionQuickFilters = {
  IsCeilingHit: {
    label: i18n.t('productions.metrics.ceiling'),
    isChecked: false,
  },
  IsFloorHit: {
    label: i18n.t('productions.metrics.floor'),
    isChecked: false,
  },
  NoComparables: {
    label: i18n.t('productions.metrics.noComps'),
    isChecked: false,
  },
  NoPricingGroup: {
    label: i18n.t('productions.metrics.noPrice'),
    isChecked: false,
  },
  within90Days: {
    label: i18n.t('productions.metrics.within90Days'),
    isChecked: false,
  },
  IsWithin90Days: {
    label: i18n.t('productions.metrics.within90Days'),
    isChecked: false,
  },
  ShowAvailableTicketGroups: {
    label: i18n.t('productions.metrics.availableTicketGroups'),
    isChecked: false,
  },
};

export const unassignedPricer = { label: i18n.t('productions.notAssigned'), value: 'unassigned', selected: false };

export const filterOptions: IProductionsFilters = {
  eventDay: {
    type: EFilterType.OPTIONS,
    data: {
      label: i18n.t(`productions.filters.eventDay`),
      options: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((item) => ({
        label: item,
        value: item,
        selected: false,
      })),
    } as IOptionsSet<string>,
  },
  status: {
    type: EFilterType.OPTIONS,
    data: {
      label: i18n.t(`productions.filters.status`),
      options: Object.values(ProductionStatus).map((item) => ({
        label: item,
        value: item,
        selected: item === ProductionStatus.ACTIVE,
      })),
    } as IOptionsSet<string>,
  },
  pricer: {
    type: EFilterType.OPTIONS,
    data: {
      label: i18n.t(`productions.filters.pricer`),
      options: [unassignedPricer],
    } as IOptionsSet<string>,
  },
  isParking: {
    type: EFilterType.RADIO,
    data: {
      label: i18n.t(`productions.filters.parking`),
      options: ThreeState,
      selected: ThreeState[0],
    } as IRadioOption,
  },
  isNoTickets: {
    type: EFilterType.RADIO,
    data: {
      label: i18n.t(`productions.filters.noTickets`),
      options: ThreeState,
      selected: ThreeState[2],
    } as IRadioOption,
  },
};

export const userIsPricer = (user: ClientUserAppProfileResponse | undefined): boolean => {
  if (!user || !user.roles || user.roles.length !== 1) {
    return false;
  }

  return user.roles[0].key === Role.PRICER;
};

export interface IProductionQueryParams {
  debouncedSearchTerm: string;
  selectedDateRange: Date[];
  pageSize: number;
  filters: IProductionsFilters;
  productionQuickFilters: IProductionQuickFilters;
  noPos: boolean;
  sortColumns: columnSortType;
  isProductionPanelOpen?: boolean;
  selectedProductionId?: string | null;
  productionPage?: number;
}

export const getProductionQueryParams = (
  filters: IProductionsFilters,
  productionQuickFilters: IProductionQuickFilters,
  sortColumns: columnSortType,
): GetProductionsQueryParams => {
  const queryParams: GetProductionsQueryParams = {};

  for (const key in productionQuickFilters) {
    if (productionQuickFilters[key as keyof typeof productionQuickFilters].isChecked) {
      // for 90 days, the dates are already set, copying the other keys to params
      if (key !== 'within90Days') {
        queryParams[key as keyof ProductionsQuickFiltersKeys] = true;
      }
    }
  }

  queryParams.DayOfWeek = filters.eventDay.data.options
    .filter((item) => item.selected)
    .map((item) => item.value as IsoDayOfWeek);

  queryParams.ProductionStatus = filters.status.data.options
    .filter((item) => item.selected)
    .map((item) => item.value as ProductionStatus);

  const selected: string[] = [];
  filters.pricer.data.options
    .filter((item) => item.selected)
    .map((item) => {
      if (item.value === unassignedPricer.value) {
        queryParams.NoPricer = true;
      } else {
        selected.push(item.value);
      }
    });
  queryParams.Pricer = selected;

  const parkingOption = getBooleanValueFromThreeState(filters.isParking.data.selected?.key as ThreeStateOption);
  if (parkingOption !== undefined) queryParams.ShowParking = parkingOption;

  const noTicketOptionOption = getBooleanValueFromThreeState(
    filters.isNoTickets.data.selected?.key as ThreeStateOption,
  );
  if (noTicketOptionOption !== undefined) queryParams.ShowAvailableTicketGroups = !noTicketOptionOption;

  const sortColumn = sortColumns as columnSortType;
  if (sortColumn.columnName !== '') {
    queryParams.SortColumn = sortColumn?.columnName as ProductionRequestSortColumn;
    queryParams.SortOrder = sortColumn?.sortDirection;
  } else {
    queryParams.SortColumn = 'EventDate';
    queryParams.SortOrder = 'Asc';
  }

  return queryParams;
};

export const queryProductions = async ({ queryKey, pageParam, signal }: any) => {
  const [, { debouncedSearchTerm, selectedDateRange, pageSize, filters, sortColumns, productionQuickFilters }]: [
    string,
    IProductionQueryParams,
  ] = queryKey;

  let startDate = '',
    endDate = '';
  try {
    startDate = format(selectedDateRange[0], SERVER_DATE_FORMAT);
    endDate = format(selectedDateRange[1], SERVER_DATE_FORMAT);
  } catch (e) {}

  const query = {
    Filter: debouncedSearchTerm,
    StartDate: startDate,
    EndDate: endDate,
    PageNumber: pageParam.toString(),
    PageSize: pageSize,
  };

  const queryParams: GetProductionsQueryParams = getProductionQueryParams(filters, productionQuickFilters, sortColumns);

  // useInfiniteQuery can be used with fetch** functions
  return fetchGetProductions(
    {
      // this is important to pass for auth
      ...AuthApiFetcherOptions,
      queryParams: {
        ...query,
        ...queryParams,
      },
    },
    signal,
  );
};

export const queryProductionsPage = async ({ queryKey, signal }: any) => {
  const [
    ,
    {
      selectedProductionId,
      debouncedSearchTerm,
      selectedDateRange,
      pageSize,
      filters,
      sortColumns,
      productionQuickFilters,
    },
  ]: [string, IProductionQueryParams] = queryKey;

  let startDate = '',
    endDate = '';
  try {
    startDate = format(selectedDateRange[0], SERVER_DATE_FORMAT);
    endDate = format(selectedDateRange[1], SERVER_DATE_FORMAT);
  } catch (e) {}

  const query = {
    InitialProductionId: Number(selectedProductionId),
    Filter: debouncedSearchTerm,
    StartDate: startDate,
    EndDate: endDate,
    PageSize: pageSize,
  };
  const queryParams = getProductionQueryParams(filters, productionQuickFilters, sortColumns);

  return fetchGetProductionsInPage(
    {
      ...AuthApiFetcherOptions,
      queryParams: {
        ...query,
        ...queryParams,
      },
    },
    signal,
  );
};
